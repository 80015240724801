export const getLocalStorageInfo = () => {
    let data = localStorage.getItem("info");
    if (data === null) {
      return null;
    } else {
      let token = JSON.parse(data).token;
      return token;
    }
  };
  export const getAccountId = () => {
    let data = localStorage.getItem("info");
    if (data === null) {
      return null;
    } else {
      let id = JSON.parse(data).account_id;
      return id;
    }
  }
  export const storeInLocalStorage = data => {
    localStorage.setItem("info", data);
  };
  export const removeInfoLocalStorage = () => {
    localStorage.removeItem("info");
    localStorage.removeItem("roles");
  
  };  
