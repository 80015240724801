import React, { Component } from "react";
import styles from "./Pagination.module.scss";
import Icon from "../Icon/Icon";
class Pagination extends Component {
  render() {
    const { pagination } = this.props.style
    return (
      <div className={pagination}>
        {!this.props.hideDetailText && (
          <p className={styles.amountText}>
            <strong>{`Showing ${
              this.props.pageNumber * 10 + this.props.pageCount
            } of
            ${this.props.amount}
             entries`}</strong>
          </p>
        )}
        <div className={styles.btnWrapper}>
          <button
            disabled={this.props.disabled}
            className={`${styles.prev} ${styles.navigation}`}
            onClick={this.props.prev}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "angle-double-left" }}
            />
          </button>

          <button className={styles.navigation}>
            {this.props.pageNumber + 1}
          </button>

          <button
            disabled={this.props.disabled || this.props.disableNext}
            className={`${styles.next} ${styles.navigation}`}
            onClick={this.props.next}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "angle-double-right" }}
            />
          </button>
        </div>
      </div>
    );
  }
}

export default Pagination;
