import React from "react";
import { Provider } from "react-redux";
import rooReducer from "./reducer";
import thunk from "redux-thunk";

import { createStore, applyMiddleware, compose } from "redux";
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
const store = createStore(rooReducer, composeEnhancers(applyMiddleware(thunk)));
export default ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
