import React, { Component } from "react";
import styles from "./Buzzer.module.scss";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Icon from "../Icon/Icon";

export default class Buzzer extends Component {
  state = {
    buzzerOn: true,
  };
  onChangeHandler = (event) => {
    this.props.click();
  };
  volumeHandler = () => {
    if (!this.props.showAudio || document.getElementById("audio") === null)
      return;
    var sound = document.getElementById("audio");
    sound.muted = this.props.muted;

    if (this.props.value && !this.props.noData && this.props.isActive) {
      sound.play();
    } else {
      sound.muted = true;
    }
  };

  render() {
    return (
      <>
        {this.props.showAudio && (
          <audio
            id="audio"
            src="https://res.cloudinary.com/hisky/video/upload/v1600351262/c51rgslpblfmpkd1mwj6.wav"
            autostart="false"
            loop={true}
          ></audio>
        )}
        {this.props.showAudio && this.volumeHandler()}
        <div className={styles.Buzzer}>
          <div className={styles.wrapper}>
            <p className={styles.title}>
              {this.props.title} {this.props.value ? "On" : "Off"}
              {this.props.showAudio && (
                <span className={styles.volume} onClick={this.props.clickMuted}>
                  {!this.props.muted ? (
                    <Icon
                      defination={{ prefix: "fas", iconName: "volume-up" }}
                    />
                  ) : (
                    <Icon
                      defination={{ prefix: "fas", iconName: "volume-slash" }}
                    />
                  )}
                </span>
              )}
            </p>

            <div
              className={
                this.props.loading || this.props.noData || !this.props.isActive
                  ? `${styles.center} ${styles.pointerEvent}`
                  : styles.center
              }
            >
              <input
                className={styles.checkbox}
                id={this.props.id}
                type="checkbox"
                value={this.props.value}
                onChange={(e) => this.onChangeHandler(e)}
                checked={this.props.value}
              ></input>
            </div>
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.props.loading ? "block" : "none" }}
            >
              <SpinnerLoader />
            </div>
          </div>
          {/* <label className={styles.switch}>
          <input type="checkbox" />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label> */}
          <p
            className={styles.msg}
            style={{ visibility: this.props.loading ? "visible" : "hidden" }}
          >
            The command was sent
          </p>
        </div>
      </>
    );
  }
}
