import React, { Component } from "react";
import styels from "./Layout.module.scss";
import Header from "../Header/Header";
import { connect } from "react-redux";

import SpinnerLoader from "../../Component/SpinnerLoader/SpinnerLoader";
import Model from "../../Component/Model/Model";
const HistorySensor = React.lazy(() =>
  import("../../Component/Model/Models/HistorySensor/HistorySensor")
);
const Configuration = React.lazy(() =>
  import("../../Component/Model/Models/Configuration/Configuration")
);
class Layout extends Component {
  render() {
    console.log("this.prosp",this.props.configRx)
    return (
      <div>
        <Model visible={this.props.openModel}>
          <React.Suspense
            fallback={
              <div className={styels.spinnerWrapper}>
                <SpinnerLoader />
              </div>
            }
          >
            {this.props.historyRx ? <HistorySensor /> : null}
            {this.props.configIpRx ? <Configuration /> : null}
          </React.Suspense>
        </Model>
        <div className={styels.Layouts}>
          <main>
            <Header />
            <div className={styels.DashBoard}>{this.props.children}</div>
          </main>
        </div>
      </div>
    );
  }
}
const mapStateHandler = (state) => {
  return {
    openModel: state.common.model,
    historyRx: state.common.historySensorRx,
    configIpRx: state.common.configRX,
  };
};
const mapStateDispatch = (dispatch) => {
  return {};
};
export default connect(mapStateHandler, mapStateDispatch)(Layout);
