import React, { Component } from "react";
import styles from "./Distance.module.scss";
import SENSOR_BOX_IMG from "../../assest/sensor-box.png";
import Icon from "../Icon/Icon";
export default class Distance extends Component {
  generateLineHandler = (val) => {
    return Array(10)
      .fill()
      .map((_, i) => {
        return val == i + 1 || (val>i && i==9) ? (
          <span className={styles.lineEnd} key={i}>
            <Icon
              defination={{ prefix: "fal", iconName: "angle-double-right" }}
            />
          </span>
        ) : (
          <span key={i} className={styles.line}>
            &nbsp;
          </span>
        );
      });
  };
  render() {
    let transmittedDistance = Math.ceil(this.props.value / 50);

    return (
      <>
        <p className={styles.textDisc}>Distance</p>
        <div className={styles.wrapper}>
          <div className={styles.lineWrapper}>
            <div className={styles.lineBox}>
              {this.generateLineHandler(transmittedDistance)}
            </div>
            <span className={styles.sensorStart}>
              <Icon
                defination={{ prefix: "fal", iconName: "sensor-on" }}
                flip="horizontal"
              />
            </span>
          </div>
          <div className={styles.distanceVal}>
            <p>Distance:&nbsp;&nbsp;{this.props.value ? ` ${this.props.value}cm` : 'N/A'}</p>
          </div>
          <div className={styles.placeholder}>
            <img at="logo"/>
          </div>
        </div>
      </>
    );
  }
}
