import * as actionType from "./types";

export const setAuth = (auth) => {
  return {
    type: actionType.SET_AUTH,
    payload: auth,
  };
};
export const openConfigModelSelecter = () => {
  return {
    type: actionType.OPEN_CONFIG,
  };
};
export const setMenuOverlaySelector = (data) => {
  return {
    type: actionType.SET_MENU_OVERLAY,
    payload: data,
  };
};
export const refreshDataSelector = (data) => {
  return {
    type: actionType.REFRESH_DATA,
    payload: data,
  };
};
export const setTriggerModeSelector = (data) => {
  return {
    type: actionType.SET_TRIGGER_MODE,
    payload: data,
  };
};
export const resetModelSelector = () => {
  return {
    type: actionType.RESET_MODEL,
  };
};

export const openHistroySensorSelecter = () => {
  return {
    type: actionType.OPEN_HISTORY,
  };
};
export const uploadMacAddressSelecter = (data) => {
  return {
    type: actionType.UPLOAD_MAC,
    payload: data,
  };
};
export const setMacIdSelector = (data) => {
  return {
    type: actionType.SET_MAC_ID,
    payload: data,
  };
};
