import React, { Component, Fragment } from "react";
import styles from "./SingleButton.module.scss";

export default class SingleButton extends Component {
  render() {
    const { text, disabled } = this.props;
    return (
      <Fragment>
        <button
          disabled={disabled}
          className={styles.btn}
          style={this.props.style}
          onClick={this.props.click}
        >
          {text}
        </button>
      </Fragment>
    );
  }
}
