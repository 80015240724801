import React, { Component } from "react";
import Icon from "../Component/Icon/Icon";
import styles from "./DistanceSlider.module.scss";
const MAX_VALUE = 500;
//const VAL = 250;
export default class DistanceSlider extends Component {
  componentDidMount() {
    this.calculatePropsHandler();
  }
  calculatePropsHandler = () => {
    if (!this.props.value || document.getElementById("pr-slider")==null)
      return { stripeLeft: 0, highlight: 0 };
    let parentProps = document
      .getElementById("pr-slider")
      .getBoundingClientRect();

    let stripeLeft = this.props.value
      ? (this.props.value / MAX_VALUE) * 100
      : 0;
    return {
      stripeLeft: stripeLeft,
      highlight: parentProps.width * (stripeLeft / 100),
    };
  };
  getInfoBoxStyles = () => {
    if (this.props.value < 50) return styles.infoboxLeft;
    else if (this.props.value > 400) return styles.infoboxRight;
    else return styles.infobox;
  };
  render() {
    let sProps = this.calculatePropsHandler();
    return (
      <>
        <p className={styles.textDisc}>Distance</p>
        <span className={styles.value}>{this.props.value} cm</span>
        <div className={styles.rangeHolder}>
          <div id="pr-slider" className={styles.dragdealer}>
            <div
              id="stripe"
              className={styles.stripe}
              style={{ left:sProps.stripeLeft===0? '3%': sProps.stripeLeft + "%" }}
            >
              <div
                className={styles.highlight}
                style={{
                  width: sProps.highlight + "px",
                  left: -sProps.highlight -1 + "px",
                  display: this.props.value ? "block" : "none",
                }}
              ></div>

              <div className={styles.handle}>
                <div className={styles.square}>
                  <span className={styles.icon}>
                    <Icon defination={{ prefix: "fal", iconName: "bars" }} />
                  </span>
                  {/* <div className={this.getInfoBoxStyles()}>
                    <div className={styles.innerbox}>
                      <div className={styles.annualLabel}>
                        Distance:&nbsp;
                        <b>{this.props.value}</b>cm
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
