import React, { Component } from "react";
import styles from "./App.module.scss";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
import { connect } from "react-redux";
import Login from "./Contianer/Login/Login";
import ResetPassword from "./Contianer/Login/ResetPassword/ResetPassword";
import Layout from "./Contianer/Layout/Layout";
import { httpRequest } from "./RequestManager/HttpRequest";
import { checkUserToken } from "./RequestManager/GqlBuilderUser";
import { getAccountDetails } from "./RequestManager/GqlBuilderAccount";
import Controller from "./Controller";
import * as actionType from "./Store/actions/index";
import {
  getLocalStorageInfo,
  removeInfoLocalStorage,
} from "./Store/localStoreageManager/localStoreageManager";
import SensorBox from "./Contianer/SensorBox/SensorBox"
import * as route from "./Util/routes";
import Spinner from "./Component/Spinner/Spinner";
import Dashboard from "./Contianer/Dashboard/DashBoard";
import PageNotFound from "./Contianer/PageNotFound/PageNotFound";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
library.add(fas, far, fal, fab);
class App extends Component {
  state = {
    isAuth: null,
  };
  componentWillMount() {
    document.getElementById("lds-spinners").style.display = "none";
  }
  componentDidMount() {
    this.checkAuthHandler();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ isAuth: nextProps.isAuth });
  }
  checkAuthHandler = () => {
    let info = getLocalStorageInfo();
    if (info === null || info === "") {
      this.setAuthHandler(false);
      this.props.setAuth(false);
    } else {
      httpRequest(checkUserToken, {})
        .then((res) => {
          if (res.data.sensorUserAuth.status) {
            const  account  = res.data.sensorUserAuth.account;
            let accInfo = {
              account_id: account.id,
              lastName: account.users[0].lastName,
              firstName: account.users[0].firstName,
              userId: account.users[0].id,
              email: account.users[0].email,
              avatar: account.users[0].avatar,
              parentIds: account.parentIds,
              role: `${account.entity.name}-${account.users[0].role.name}`,
              entity_name: account.entity.name,
              phone: account.users[0].phone,
            };
            Controller.setAccountInfo(accInfo);
            this.props.setAuth(true);           
            
          } else {            
            this.removeLoggedInUserInfoHandler();
          }
        })
        .catch((err) => {          
          this.removeLoggedInUserInfoHandler();
        });
    }
  };
  removeLoggedInUserInfoHandler = () => {
    removeInfoLocalStorage();
    this.setAuthHandler(false);
    this.props.setAuth(false);
  };
  /**
   * update the user auth in state and store
   */
  setAuthHandler = (isAuth) => {
    this.setState({ isAuth: isAuth });
  };
  getUrlIdHandler = () => {
    let url = document.URL.split("/");
    return url[url.length - 1];
  };
  render() {
    return (
      <div className={styles.App}>
        {this.state.isAuth !== null ? (
          <>
            {this.state.isAuth ? (
              <Layout accountInfo={this.state.accountInfo}>
                <Switch>
                <Route path={route.SENSOR_BOX} component={SensorBox}  />
                  <Route path={route.ROOT} component={Dashboard} exact />
              
                  <Route component={PageNotFound} />
                </Switch>
              </Layout>
            ) : (
              <>
                <Switch>
                  <Route path={route.LOGIN} component={Login} />
                  <Route
                    path={route.RESETPASSWORD + "/:id"}
                    component={ResetPassword}
                  />
                </Switch>
                {window.location.pathname.includes("/reset-password") ? (
                  <Redirect
                    to={route.RESETPASSWORD + "/" + this.getUrlIdHandler()}
                  />
                ) : (
                  <Redirect to={route.LOGIN} />
                )}
              </>
            )}
          </>
        ) : (
          <Spinner />
        )}
        {/* <Login /> */}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    isAuth: state.common.isAuth,
  };
};
const mapDispatchProps = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
  };
};
export default connect(mapStateProps, mapDispatchProps)(withRouter(App));
