import React, { Component } from "react";
import styles from "./SensorBox.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import LightSwitch from "../../Component/LightSwitch/LightSwitch";

import RotateSpier from "../../Component/RotateSpier/RotateSpier";
import ProgressBar from "../../Component/ProgressBar/ProgressBar";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  commandSensorBox,
  getSensorBoxList,
  getSensorBoxData,
  getSensorBoxListAndData,
} from "../../RequestManager/GqlBuilderSensor";
import * as actionType from "../../Store/actions/index";
import Distance from "../../Component/Distance/Distance";
import Icon from "../../Component/Icon/Icon";
import Buzzer from "../../Component/Buzzer/Buzzer";
import DistanceSlider from "../../DistanceSlider/DistanceSlider";
import SingleButton from "../../Component/SingleButton/SingleButton";

class ThingBoard extends Component {
  _isMounted = true;
  _isActiveSensorBox = true;
  interval_trigger = 5000;
  request = [];
  stopInterval = false;
  responseList = [];
  timer = null;
  box_timer = null;
  state = {
    macId: null,
    data: {},
    errors: [],
    sensorList: [],
    activeCount: 0,
    inactiveCount: 0,
    muted: false,
    listLoading: false,
    loading: false,
    isPhone: false,
    isTablet: false,
    isDesktop: true,
    changeTempMode: false,
    activeSensorsLimit: "all",
    inactiveSensorsLimit: "all",
    noData: true,
    loadingType: {
      red_led: false,
      blue_led: false,
      green_led: false,
      buzzer: false,
      relay: false,
    },
    status: {
      red_led: false,
      blue_led: false,
      green_led: false,
      buzzer: false,
      relay: false,
      distance: 0,
      sound: "",
      light: "",
      rotation: "",
    },
    selectedBoxTimestamp: "",
  };
  componentWillMount() {
    this.handleResize();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.refershDataRx) {
      this.stopInterval = false;
      this.createHttpRequestHandler(this.state.macId);
    }
  }
  triggerActionHandler = (data) => {
    if (this.state.noData || !this._isActiveSensorBox) return;
    let temp_type = data;
    this.request.push(data);

    clearTimeout(this.box_timer);
    this.box_timer = null;

    let check_loading = { ...this.state.loadingType };
    let type = data.split(":")[0].toLowerCase().replace('"', "");
    type = type.replace('"', "");

    check_loading[type] = !this.state.loadingType[type];
    this.setState({ loadingType: check_loading }, () => {});
    if (this.timer == null) {
      this.timer = setTimeout(() => {
        this.props.setTriggerModeSelector(true);
        this.request.map((item, index) => {
          var _i = item.replaceAll('"', '\\"');

          this.responseList.push(
            httpRequest(commandSensorBox, {
              cmd: _i,
              ip: "213.57.68.209",
              port: 8080,
              mac_id: this.state.macId,
            }).then((res) => {
              return Promise.resolve(res);
            })
          );
        });
        Promise.all(this.responseList)
          .then((val) => {
            this.request = [];
            clearTimeout(this.timer);
            this.timer = null;
            this.checkErrorTrigerHandler(val);
            this.interval_trigger = 150;
            this.props.setTriggerModeSelector(false);
            this.createHttpRequestHandler(this.state.macId);
          })
          .catch((err) => {});
      }, 500);
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    clearTimeout(this.box_timer);
    this.timer = null;
    this.box_timer = null;
  }
  onClickMutedHandler = () => {
    this.setState({ muted: !this.state.muted });
  };
  componentDidMount() {
    this.createHttpRequestHandler(this.state.macId);
  }
  handleResize = (e) => {
    let isPhone = false;
    let isTablet = false;
    let isDesktop = this.state.isDesktop;
    let screenWidth = window.innerWidth;
    if (screenWidth <= 640) {
      isPhone = true;
      isDesktop = false;
      this.props.setMenuOverlaySelector(true);
      document.body.classList.remove("noScrollBody");
    }
    if (screenWidth <= 1024 && screenWidth >= 641) {
      isTablet = true;
      isDesktop = false;
    }
    this.setState(
      { isPhone: isPhone, isTablet: isTablet, isDesktop: isDesktop },
      () => {}
    );
  };
  checkErrorTrigerHandler = (error_arr) => {
    let test = [];
    for (let i = 0; i < error_arr.length; i++) {
      if (error_arr[i].errors !== undefined) {
        test.push(error_arr[0].message.replace(/_/g, " "));
      }
    }
    this.setState({ errors: test });
  };
  convertDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };
  checkRespondDataHandler = (data, macId) => {
    let noData = false;
    if (this.timer == null) {
      let copy = { ...this.state.status };
      let copyLoading = { ...this.state.loadingType };
      if (data.sensorboxData !== null) {
        copy.red_led = data.sensorboxData.redLight;
        copy.green_led = data.sensorboxData.greenLight;
        copy.blue_led = data.sensorboxData.blueLight;
        copy.relay = data.sensorboxData.relay;
        copy.light = data.sensorboxData.light;
        copy.sound = data.sensorboxData.sound;
        copy.distance = data.sensorboxData.dist;
        copy.buzzer = data.sensorboxData.buzzer;
        copy.rotation = data.sensorboxData.angle;
        copyLoading.red_led =
          this.state.status.red_led !== data.sensorboxData.redLight
            ? false
            : copyLoading.red_led;
        copyLoading.green_led =
          this.state.status.green_led !== data.sensorboxData.greenLight
            ? false
            : copyLoading.green_led;
        copyLoading.blue_led =
          this.state.status.blue_led !== data.sensorboxData.blueLight
            ? false
            : copyLoading.blue_led;
        copyLoading.buzzer =
          this.state.status.buzzer !== data.sensorboxData.buzzer
            ? false
            : copyLoading.buzzer;
        copyLoading.relay =
          this.state.status.relay !== data.sensorboxData.relay
            ? false
            : copyLoading.relay;
        if (
          this.state.status.relay !== data.sensorboxData.relay ||
          this.state.status.buzzer !== data.sensorboxData.buzzer ||
          this.state.status.blue_led !== data.sensorboxData.blueLight ||
          this.state.status.green_led !== data.sensorboxData.greenLight ||
          this.state.status.red_led !== data.sensorboxData.redLight
        ) {
          this.interval_trigger = 5000;
        }
        this.setState(
          {
            status: copy,
            macId: macId,
            loadingType: copyLoading,
            errors: [],
            noData: noData,
            sensorboxList: data.sensorboxList
              ? data.sensorboxList
              : this.state.sensorboxList,
            selectedBoxTimestamp: data.sensorboxData.timestamp,
          },
          () => {
            this.box_timer = setTimeout(() => {
              this.createHttpRequestHandler(this.state.macId);
            }, this.interval_trigger);
          }
        );
      } else {
        copy.red_led = false;
        copy.green_led = false;
        copy.blue_led = false;
        copy.relay = false;
        copy.light = "";
        copy.sound = "";
        copy.distance = 0;
        copy.buzzer = false;
        copy.rotation = "";
        noData = true;
        copyLoading.red_led = false;
        copyLoading.blue_led = false;
        copyLoading.green_led = false;
        copyLoading.buzzer = false;
        copyLoading.relay = false;
        this.setState(
          {
            status: copy,
            macId: macId,
            loadingType: copyLoading,
            errors: [],
            noData: noData,
            selectedBoxTimestamp: "",
          },
          () => {
            this.box_timer = setTimeout(() => {
              this.createHttpRequestHandler(this.state.macId);
            }, this.interval_trigger);
          }
        );
      }
    } else {
    }
  };
  checkRespondListHandler = (data) => {
    if (data.sensorboxList == null) return;

    this.setState({
      sensorList: data.sensorboxList.active.concat(data.sensorboxList.inactive),
      activeCount: data.sensorboxList.activeCount,
      inactiveCount: data.sensorboxList.inactiveCount,
    });
  };
  resetLoadingAndValues = (macId = null) => {
    let copy = { ...this.state.status };
    let copyLoading = { ...this.state.loadingType };
    copy.red_led = false;
    copy.green_led = false;
    copy.blue_led = false;
    copy.relay = false;
    copy.light = "";
    copy.sound = "";
    copy.distance = 0;
    copy.buzzer = false;
    copy.rotation = "";
    copyLoading.red_led = false;
    copyLoading.blue_led = false;
    copyLoading.green_led = false;
    copyLoading.buzzer = false;
    copyLoading.relay = false;
    this.setState(
      {
        loadingType: copyLoading,
        status: copy,
        macId: macId,
        noData: true,
        selectedBoxTimestamp: "",
      },
      () => {
        this.createHttpRequestHandler(this.state.macId);
      }
    );
  };
  createHttpRequestHandler = (data = null) => {
    let dataParams = {};
    if (this._isMounted) {
      if (data === null) {
        httpRequest(getSensorBoxList, dataParams).then((res) => {
          this.props.refreshDataSelector(false);
          this.checkRespondListHandler(res.data);
        });
        /////get list of sensor box query
      } else {
        if (typeof data === "object") {
          this._isActiveSensorBox = data.isActive;
        }

        let macId = typeof data === "object" ? data.macId : data;
        if (macId !== this.state.macId) {
          clearTimeout(this.box_timer);
          this.box_timer = null;
          this.resetLoadingAndValues(macId);
        } else {
          dataParams.mac_address = macId;
          let queryType = getSensorBoxData;
          if (this.interval_trigger === 5000) {
            queryType = getSensorBoxListAndData;
          }
          if (!this.stopInterval) {
            httpRequest(queryType, dataParams).then((res) => {
              this.props.refreshDataSelector(false);
              this.checkRespondDataHandler(res.data, macId);
            });
          }
        }

        /////get the sensor box query
      }
    }
  };
  openConfigHandler = () => {
    this.stopInterval = true;
    this.props.setMacIdSelector(this.state.macId);
    this.props.openConfigModelSelecter();
  };
  onClickHistorySensorhandler = () => {
    clearTimeout(this.box_timer);
    this.box_timer = null;
    this.props.setMacIdSelector(this.state.macId);
    this.props.openHistoryModelSelecter();
  };
  limitsChangedHandler = (data) => {};
  getTypeOfTriggerHandler = (type) => {
    return this.state.status[type] ? "OFF" : "ON";
  };
  render() {
    return (
      <div className={styles.SensorBoxs}>
        <div
          className={
            this.props.menuOverlayRx
              ? `${styles.overlay} ${styles.searchWrapper}`
              : styles.searchWrapper
          }
        >
          <SearchBar
            sensorsList={this.state.sensorList}
            activeCount={this.state.activeCount}
            inactiveCount={this.state.inactiveCount}
            isPhone={this.state.isPhone}
            isTablet={this.state.isTablet}
            isDesktop={this.state.isDesktop}
            macSelected={(macId) => this.createHttpRequestHandler(macId)}
            limitsChanged={(data) => this.limitsChangedHandler(data)}
          />
        </div>
        <div className={styles.dataWrapperInfo}>
          <div className={styles.leftSide}>
            <div className={styles.progressWrapper}>
              <ProgressBar
                title="Light"
                subtitle="Lumens"
                value={
                  this.state.status.light !== ""
                    ? parseInt(this.state.status.light)
                    : ""
                }
              />
              <ProgressBar
                title="Sound"
                subtitle="Sound"
                value={
                  this.state.status.sound !== ""
                    ? parseInt(this.state.status.sound)
                    : ""
                }
              />
            </div>

            <div className={styles.bottomLeftDistance}>
              <DistanceSlider
                value={
                  this.state.status.distance
                    ? parseInt(this.state.status.distance)
                    : 0
                }
              />
            </div>
            <div className={styles.bottomLeftRotate}>
              <RotateSpier
                value={
                  this.state.status.rotation !== ""
                    ? parseInt(this.state.status.rotation)
                    : ""
                }
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <div
              className={styles.toggleWrapper}
              style={{
                cursor:
                  this.state.noData || !this._isActiveSensorBox
                    ? "not-allowed"
                    : "",
              }}
            >
              <Buzzer
                id="tog-1"
                title="Buzzer"
                clickMuted={this.onClickMutedHandler}
                muted={this.state.muted}
                loading={this.state.loadingType.buzzer}
                click={() =>
                  this.triggerActionHandler(
                    `"BUZZER":"${this.getTypeOfTriggerHandler("buzzer")}"`
                  )
                }
                value={this.state.status.buzzer}
                noData={this.state.noData}
                showAudio={true}
                isActive={this._isActiveSensorBox}
              />
              <Buzzer
                id="tog-2"
                title="Relay"
                loading={this.state.loadingType.relay}
                value={this.state.status.relay}
                click={() =>
                  this.triggerActionHandler(
                    `"RELAY":"${this.getTypeOfTriggerHandler("relay")}"`
                  )
                }
                noData={this.state.noData}
                isActive={this._isActiveSensorBox}
              />
            </div>
            <div
              className={styles.lightWrapper}
              style={{
                cursor:
                  this.state.noData || !this._isActiveSensorBox
                    ? "not-allowed"
                    : "",
              }}
            >
              <LightSwitch
                id="light-1"
                click={() =>
                  this.triggerActionHandler(
                    `"GREEN_LED":"${this.getTypeOfTriggerHandler("green_led")}"`
                  )
                }
                ledColor="green"
                loading={this.state.loadingType.green_led}
                value={this.state.status.green_led}
                noData={this.state.noData}
                isActive={this._isActiveSensorBox}
              />
              <LightSwitch
                id="light-2"
                click={() =>
                  this.triggerActionHandler(
                    `"RED_LED":"${this.getTypeOfTriggerHandler("red_led")}"`
                  )
                }
                ledColor="red"
                loading={this.state.loadingType.red_led}
                value={this.state.status.red_led}
                noData={this.state.noData}
                isActive={this._isActiveSensorBox}
              />
              <LightSwitch
                id="light-3"
                click={() =>
                  this.triggerActionHandler(
                    `"BLUE_LED":"${this.getTypeOfTriggerHandler("blue_led")}"`
                  )
                }
                ledColor="blue"
                loading={this.state.loadingType.blue_led}
                value={this.state.status.blue_led}
                noData={this.state.noData}
                isActive={this._isActiveSensorBox}
              />
            </div>
            <p
              className={styles.noTransmittingMsg}
              style={{
                visibility: !this.state.noData ? "visible" : "hidden",
              }}
            >
              Sensor box is{" "}
              <b>{this._isActiveSensorBox ? "active" : "not active"}</b>.
              <br />
              Last activity record date :
              {this.state.selectedBoxTimestamp
                ? this.convertDateHandler(this.state.selectedBoxTimestamp)
                : "N/A"}
              <span
                className={styles.iconHistory}
                onClick={() => this.onClickHistorySensorhandler()}
              >
                <Icon defination={{ prefix: "fas", iconName: "history" }} />
                <span className={styles.toolTip}>Sensor History</span>
              </span>
              <span
                className={styles.iconConfiguration}
                onClick={this.openConfigHandler}
              >
                <Icon
                  defination={{ prefix: "fal", iconName: "network-wired" }}
                />
                <span className={styles.toolTip}>Configuration</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    menuOverlayRx: state.common.menuOverlayRx,
    refershDataRx: state.common.refershDataRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
    setMacIdSelector: (auth) => dispatch(actionType.setMacIdSelector(auth)),
    setMenuOverlaySelector: (data) =>
      dispatch(actionType.setMenuOverlaySelector(data)),
    refreshDataSelector: (data) =>
      dispatch(actionType.refreshDataSelector(data)),
    setTriggerModeSelector: (data) =>
      dispatch(actionType.setTriggerModeSelector(data)),
    openHistoryModelSelecter: () =>
      dispatch(actionType.openHistroySensorSelecter()),
    openConfigModelSelecter: () =>
      dispatch(actionType.openConfigModelSelecter()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(withRouter(ThingBoard));
