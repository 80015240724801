var dt_template = {
  query: "",
  variables: {},
};
export const resetSensorDataRequest = (data) => {
  let resetData = {
    query:
    "query{\n  autoSensorData\n}",
    variables: {},
  };
  dt_template.query = resetData.query;
  dt_template.variables = resetData.variables;
  return dt_template;
};
export const buildCreateUserRequest = (data) => {
  let createUser = {
    query:
      "mutation ($email: String!) {\n  createUser(email: $email) {\n    user {\n      id\n      email\n      notes\n    }\n    pwUpdateToken\n    message\n  }\n}\n",
    variables: {
      email: data.email,
    },
  };
  dt_template.query = createUser.query;
  dt_template.variables = createUser.variables;
  return dt_template;
};

export const buildLoginRequest = (data) => {
  let login = {
    query:
      "mutation ($user_name: String!, $password: String!) {\n  login(userName: $user_name, password: $password) {\n    token\n     user {\n      id\n   userName\n   email\n  phone\n    lastName\n      avatar\n      firstName\n      role {\n        name\n      }\n      account {\n        parentIds\n     freshdeskApikey   entity{\n          name\n        }\n        id\n \n      }\n    }\n  }\n}\n",
    variables: {
      user_name: data.user_name,
      password: data.password,
    },
  };

  dt_template.query = login.query;
  dt_template.variables = login.variables;
  return dt_template;
};

export const updatePasswordRequest = (data) => {
  let updatePassword = {
    query:
      "mutation ($pw_update_token: String!, $password: String!) {\n  updatePassword(pwUpdateToken: $pw_update_token, password: $password) {\n\t\tuser {\n      account{\n        id\n      }\n\t\t}\n    token\n    message\n    \n  }\n}\n",
    variables: {
      pw_update_token: data.pw_update_token,
      password: data.password,
    },
  };
  dt_template.query = updatePassword.query;
  dt_template.variables = updatePassword.variables;
  return dt_template;
};

export const updateUserInfo = (data) => {
  let updateUser = {
    query:
      "mutation ($id: ID!, $notes: String, $first_name: String, $last_name: String, $phone: String,$avatar:String, $role: Int,$priority_id:String, $user_name:String, $old_pass: String, $new_pass: String) {\n  updateUser(oldPassword: $old_pass, newPassword: $new_pass, notes: $notes, firstName: $first_name,priorityId:$priority_id, lastName: $last_name, id: $id,phone: $phone,avatar:$avatar, role:$role, userName:$user_name) {\n    user {\n      id\n      email\n      phone\n      firstName\n      lastName\n  userName\n      role {\n      id\n      name\n    }\n notes\n      avatar\n    }\n    message\n  }\n}\n",
    variables: {
      first_name: data.first_name,
      id: data.id,
      last_name: data.last_name,
      notes: data.notes,
      phone: data.phone,
      role: data.role,
      user_name: data.user_name,
      priority_id: data.priority_id,
      old_pass: data.old_pass,
      new_pass: data.new_pass,
    },
  };
  dt_template.query = updateUser.query;
  dt_template.variables = updateUser.variables;
  return dt_template;
};

export const checkUserToken = (data) => {
  let userAuth = {
    query: "query {\n  sensorUserAuth {\n    status\n    account {\n    id  parentIds name   users {\n      firstName\n      lastName\n      email\n      phone\n      avatar\n      role {\n        id\n        name\n      }\n      notes\n      isActive\n      id\n      account {        \n        parentIds\n        priorityId\n        name\n        id\n      }\n      dateJoined\n    }\n    entity {\n      name\n      id\n    }\n    isActive\n    primaryIndustry\n    group\n    \n  }\n  \n  }}\n",
    variables: {},
  };
  dt_template.query = userAuth.query;
  dt_template.variables = userAuth.variables;
  return dt_template;
};

export const getUserProfile = (data) => {
  let user = {
    query:
      "query ($user_id: Int) {\n  user(userId: $user_id) {\n    id\n    email\n\n    firstName\n    lastName\n    avatar\n    account {\n      id\n    }\n  }\n}\n",
    variables: {
      user_id: data.user_id,
    },
  };
  dt_template.query = user.query;
  dt_template.variables = user.variables;
  return dt_template;
};
export const updateAvatar = (data) => {
  let updateUser = {
    query:
      "mutation ($id: ID!,$avatar:String) {\n  updateUser(id: $id,avatar:$avatar) {\n    user {\n      id\n      avatar\n    }\n    message\n  }\n}\n",
    variables: {
      id: data.id,
      avatar: data.avatar,
    },
  };
  dt_template.query = updateUser.query;
  dt_template.variables = updateUser.variables;
  return dt_template;
};

export const requestResetPassword = (data) => {
  let reqReset = {
    "query": "mutation($email:String!, $continueTo: String){\n  requestResetPassword(email: $email, continueTo: $continueTo) {\n    message\n  }\n}",
    variables: {
      email: data.email,
      continueTo:data.continueTo
    },
  };
  dt_template.query = reqReset.query;
  dt_template.variables = reqReset.variables;
  return dt_template;
};
