import React, { Component } from "react";
import styles from './SelectWithOptions.module.scss';
export default class SelectWithOptions extends Component {
  
  render() {
    return (
      <div className={this.props.wrapperClass}>
        <label className={styles.label}>{this.props.title} </label>
        <select
          id="selectWithOption"
          className={styles.select}
          onChange={this.props.onchange}
          name={this.props.name}      
          value={this.props.selected}  
          disabled={this.props.disable || !this.props.optionsList.length}
        >
          {this.props.defaultOptionTitle && <option key='-1' value='-1'>{this.props.defaultOptionTitle}</option> }
          {/* {this.props.optionsList.length ==0 && 
            Array(6)
            .fill()
            .map((_, i) => {
              return (<option value=''></option>)})
          } */}
          {this.props.optionsList.map((item, index) => {            
            return (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>        
        <p
          className={styles.error}
          style={{ visibility: this.props.error ? "visible" : "hidden" }}
        >
          {this.props.errMsg}
        </p>
      </div>
    );
  }
}
