import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import styles from "./MulitiChart.module.scss";
import SpinnerLoader from "../../Component/SpinnerLoader/SpinnerLoader";
import moment from "moment";

var datasets = [
  {
    fill: false,
    lineTension: 0.5,
    backgroundColor: "#fff",
    borderColor: "#685C74",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#321FDB",
    pointBackgroundColor: "#321FDB",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    borderWidth: 3,
    pointHoverBackgroundColor: "#321FDB",
    pointHoverBorderColor: "#321FDB",
    pointHoverBorderWidth: 4,
    pointRadius: 3,
    pointHitRadius: 10,
    data: [],
    label: "Temperature",
    id: "temperature",
  },
  {
    fill: false,
    lineTension: 0.5,
    backgroundColor: "#fff",
    borderColor: "#72A7C5",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#321FDB",
    pointBackgroundColor: "#321FDB",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    borderWidth: 3,
    pointHoverBackgroundColor: "#321FDB",
    pointHoverBorderColor: "#321FDB",
    pointHoverBorderWidth: 4,
    pointRadius: 3,
    pointHitRadius: 10,
    data: [],
    label: "Humidity",
    id: "humidity",
  },
  {
    fill: false,
    lineTension: 0.9,
    backgroundColor: "#fff",
    borderColor: "#00bdd6",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#321FDB",
    pointBackgroundColor: "#321FDB",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    borderWidth: 3,
    pointHoverBackgroundColor: "#321FDB",
    pointHoverBorderColor: "#321FDB",
    pointHoverBorderWidth: 4,
    pointRadius: 3,
    pointHitRadius: 10,
    data: [],
    label: "Battery",
    id: "battery",
  },
];
var tempmode = false;
class MulitiChart extends Component {
  humd = {};
  temp = {};
  bat = {};
  state = {
    data: [],
    macId: null,
    labels: [],
    changeTempMode: false,
    loading: false,

    datafilter: {
      temperature: {
        data: [],
        show: true,
      },
      humidity: {
        data: [],
        show: true,
      },
      battery: {
        data: [],
        show: true,
      },
    },
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.macId !== this.state.macId ||
      nextProps.loading !== this.state.loading
    ) {
      this.setState({ loading: true }, () => {
        this.buildChartDataHandler(
          nextProps.tempData,
          nextProps.humidData,
          nextProps.batData,
          nextProps.macId
        );
      });
    }
  }
  componentDidMount() {
    this.setState({ labels: this.createLabelArrayHandler() });
  }
  createLabelArrayHandler = () => {
    const nowCopy = new Date();
    let temp = [];
    let t = {};
    for (let i = 0; i < 30; i++) {
      temp.unshift(moment(nowCopy).subtract(i, "minutes").format("mm"));
    }

    return temp;
  };
  resetDataFilterHandelr = () => {
    let filter = { ...this.state.datafilter };
    for (let key in filter) {
      filter[key].show = true;
      filter[key].data = [];
    }
    return filter;
  };

  storeDataFilterDataHandler = (dataFilter, key_d, data) => {
    dataFilter[key_d].data = data;
    return [];
  };
  buildChartDataHandler = (temp_d, humd_d, bat_d, macId) => {
    if (
      !temp_d.length &&
      !humd_d.length &&
      !bat_d.length &&
      this.props.macId == this.state.macId
    )
      return;
    let humd = {};
    let temp = {};
    let bat = {};
    var labels = this.createLabelArrayHandler();
    for (let i = 0; i < labels.length; i++) {
      humd[labels[i].toString()] = 0;
      temp[labels[i].toString()] = 0;
      bat[labels[i].toString()] = 0;
    }

    for (let i = 0; i < temp_d.length; i++) {
      var local = moment.utc(temp_d[i].timestamp).local().format();
      let min = moment(local).format("mm");
      if (temp[min.toString()] !== undefined) {
        temp[min.toString()] = temp_d[i].temperature;
      }
    }
    for (let i = 0; i < humd_d.length; i++) {
      var local = moment.utc(humd_d[i].timestamp).local().format();
      let min = moment(local).format("mm");
      if (humd[min.toString()] !== undefined) {
        humd[min.toString()] = humd_d[i].humidity;
      }
    }

    for (let i = 0; i < bat_d.length; i++) {
      var local = moment.utc(bat_d[i].timestamp).local().format();
      let min = moment(local).format("mm");
      if (bat[min.toString()] !== undefined) {
        bat[min.toString()] = bat_d[i].battery;
      }
    }

    let copyDataSet = JSON.parse(JSON.stringify(datasets));
    for (let i = 0; i < copyDataSet.length; i++) {
      let ext = [];
      if (copyDataSet[i].id === "temperature" &&this.state.datafilter.temperature.show  ) {
        copyDataSet[i].data = Object.values(temp);
      } else if (copyDataSet[i].id === "humidity" && this.state.datafilter.humidity.show) {
        copyDataSet[i].data = Object.values(humd);
      } else if (copyDataSet[i].id === "battery" &&this.state.datafilter.battery.show ) {
        copyDataSet[i].data = Object.values(bat);
      }
    }
    this.humd = humd;
    this.temp = temp;
    this.bat = bat;
    this.setState(
      {

        data: copyDataSet,
        loading: false,
        macId: macId,
        labels: labels,
        changeTempMode: false,
      },
      () => {
      
      }
    );
  };
  removeAddDataChartHandler = (key_d, status) => {
    let copy_filter = { ...this.state.datafilter };
    let dataCopy = [...this.state.data];

    if (status) {
      let temp = copy_filter[key_d].data;
      temp = [...temp];
      for (let i = 0; i < dataCopy.length; i++) {
        if (key_d === dataCopy[i].id) {
          dataCopy[i].data = temp;
          copy_filter[key_d].data = [];
          return { data_copy: dataCopy, copy_filter: copy_filter };
        }
      }
    } else {
      for (let i = 0; i < dataCopy.length; i++) {
        if (key_d === dataCopy[i].id) {
          let temp = dataCopy[i].data;
          temp = [...temp];
          copy_filter[key_d].data = temp;
          dataCopy[i].data = [];
          return { data_copy: dataCopy, copy_filter: copy_filter };
        }
      }
    }
  };
  onClickLegendHandler = (name) => {
    
    if (this.state.loading) return;
  
    let copy_filter = { ...this.state.datafilter };
    let copyData=JSON.parse(JSON.stringify(this.state.data))

    if (!this.state.datafilter[name].show){
      copy_filter[name].show = !this.state.datafilter[name].show;
      if (name=="temperature"){
        copyData[0].data=Object.values(this.temp)

      }else if(name==="humidity"){
        copyData[1].data=Object.values(this.humd)

      }else if(name==="battery"){
        copyData[2].data=Object.values(this.bat)
      }
    }else{
      copy_filter[name].show = !this.state.datafilter[name].show;
      if (name=="temperature"){
        copyData[0].data=[]

      }else if(name==="humidity"){
        copyData[1].data=[]

      }else if(name==="battery"){
        copyData[2].data=[]
      }
    }

    this.setState({ datafilter: copy_filter,data:copyData }, () => {});
  };
  calcuateTempetureTypeHandler = (data) => {
    ///true is C
    if (!this.state.changeTempMode) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== 0) {
          data[i] = (data[i] * 9) / 5 + 32;
        }
      }
      return data;
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== 0) {
          data[i] = ((data[i] - 32) * 5) / 9;
        }
      }
      return data;
    }
  };
  changeTemparatureModeHandler = () => {
    let dataSet = [...this.state.data];

    for (let i = 0; i < dataSet.length; i++) {
      if (dataSet[i].id === "temperature") {
        dataSet[i].data = this.calcuateTempetureTypeHandler(dataSet[i].data);
      }
    }
    this.setState(
      { changeTempMode: !this.state.changeTempMode, data: dataSet },
      () => {
        tempmode = this.state.changeTempMode;
      }
    );
  };

  render() {
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      animationEnabled: true,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "",
            },
            ticks: { display: true },
            gridLines: {
              display: true,
              color: "#E8E8E8",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            scaleLabel: {
              display: true,
            },
            ticks: {
              display: true,
            },
            gridLines: {
              display: true,
              color: "#E8E8E8",
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label + "min";
          },
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label === "Humidity") {
              return label + ": " + tooltipItem.value + "%";
            } else if (label === "Battery") {
              return label + ": " + tooltipItem.value + "V";
            } else {
              return (
                label +
                ": " +
                Math.round(tooltipItem.value * 10) / 10 +
                (tempmode ? "\xB0F" : "\xB0C")
              );
            }
          },
        },
      },
    };
    return (
      <div className={`${this.props.style} ${styles.chartLine}`}>
        <div className={styles.macId}>{this.props.macId}</div>
        <span
          className={
            this.state.changeTempMode
              ? `${styles.tempIcon} ${styles.celsiusIcon}`
              : `${styles.tempIcon} ${styles.farenIcon}`
          }
          onClick={this.changeTemparatureModeHandler}
        ></span>
        <div className={styles.customLegend}>
          <div className={styles.typeWrapper}>
            <span
              name="temperature"
              onClick={() => this.onClickLegendHandler("temperature")}
              className={`${styles.rectangle}`}
              style={{
                backgroundColor: "#685C74",
                opacity: this.state.datafilter.temperature.show ? 1 : 0.6,
                cursor: this.state.loading ? "not-allowed" : "pointer",
              }}
            >
              <span
                className={styles.slantLine}
                style={{
                  visibility: this.state.datafilter.temperature.show
                    ? "hidden"
                    : "visible",
                }}
              ></span>
            </span>
            <span className={styles.nameChart}>Temperature</span>
          </div>
          <div className={styles.typeWrapper}>
            <span
              name="humidity"
              onClick={() => this.onClickLegendHandler("humidity")}
              className={styles.rectangle}
              style={{
                backgroundColor: "#72A7C5",
                opacity: this.state.datafilter.humidity.show ? 1 : 0.6,
                cursor: this.state.loading ? "not-allowed" : "pointer",
              }}
            >
              <span
                className={styles.slantLine}
                style={{
                  visibility: this.state.datafilter.humidity.show
                    ? "hidden"
                    : "visible",
                }}
              ></span>
            </span>
            <span className={styles.nameChart}>Humidity</span>
          </div>
          <div className={styles.typeWrapper}>
            <span
              name="battery"
              onClick={() => this.onClickLegendHandler("battery")}
              className={styles.rectangle}
              style={{
                backgroundColor: "#00bdd6",
                cursor: this.state.loading ? "not-allowed" : "pointer",
                opacity: this.state.datafilter.battery.show ? 1 : 0.6,
              }}
            >
              <span
                className={styles.slantLine}
                style={{
                  visibility: this.state.datafilter.battery.show
                    ? "hidden"
                    : "visible",
                }}
              ></span>
            </span>

            <span className={styles.nameChart}>Battery</span>
          </div>
        </div>

        <Line
          options={options}
          data={{
            labels: this.state.labels,
            datasets: this.state.data,
            fill: false,
          }}
        />
      </div>
    );
  }
}
export default MulitiChart;
