import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class Icon extends Component {
  render() {    
    return (
      <FontAwesomeIcon
        icon={[this.props.defination.prefix, this.props.defination.iconName]}
        spin={this.props.isSpinner}
        flip={this.props.flip}
        //size={size}
        //className={className}
      />
    );
  }
}
