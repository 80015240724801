import React, { Component } from "react";
import styles from "./DataInfo.module.scss";
import Icon from "../../Component/Icon/Icon";
import moment from "moment";
import { DATE, VALUE } from "../../Util/Util";
let header = ["#", DATE, VALUE];

class DataInfo extends Component {
  converDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };
  convertAmountTypehandler = (amount) => {
    if (typeof amount === "boolean") {
      return amount ? "ACTIVE" : "INACTIVE";
    } else if (amount === null || amount === "") {
      return "N/A";
    } else {
      return Number.parseFloat(amount).toFixed(2);
    }
  };
  getEmptyRowHandler = () => {
    let temp = [];
    for (let i = 0; i < 3; i++) {
      temp.push(
        <div key={i} className={styles.emptyRow}>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
        </div>
      );
    }
    return temp;
  };
  render() {
    return (
      <div
        className={styles.DataInfo}
       
      >
        <div className={styles.info}>
          <div className={styles.topInfo}>
        
            <div
              className={styles.floatingBox}
              style={{
                background:
                  !this.props.loading && this.props.list_data.length === 0
                    ? "#eae9e9"
                    : null,
              }}
            >
              <span
                className={styles.icon}
                style={{
                  fontSize:
                    this.props.font !== undefined ? this.props.font : "",
                }}
              >
                <Icon defination={this.props.icon} />
              </span>
              <span
              className={styles.spinnerIcon}
              style={{ visibility: this.props.loading? "visible" : "hidden" }}
            >
              <Icon
                isSpinner={true}
                defination={{ prefix: "fal", iconName: "spinner" }}
              />
            </span>
            </div>
            <div className={styles.titleWrapper}>
              <p
                className={styles.title}
                style={{
                  color:
                    !this.props.loading && this.props.list_data.length === 0
                      ? "#eae9e9"
                      : null,
                }}
              >
                {this.props.title}
              </p>
            </div>
            <h3 className={styles.subTitle}>
              {this.convertAmountTypehandler(this.props.amount)}
            </h3>
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.tableRow}>
              {header.map((item, index) => {
                return (
                  <div className={styles.textCell} key={index}>
                    {item}
                  </div>
                );
              })}
            </div>
            {!this.props.loading && this.props.list_data.length === 0
              ? this.getEmptyRowHandler()
              : this.props.list_data.map((item, index) => {
                  return (
                    <div className={styles.tableRow} key={index}>
                      <div className={styles.textCell}>{index + 1}</div>
                      <div className={styles.textCell}>
                        {this.converDateHandler(item.timestamp)}
                      </div>
                      <div className={styles.textCell}>
                        {this.convertAmountTypehandler(
                          item[this.props.key_data]
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}

export default DataInfo;
