import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Header.module.scss";
import Logo from "../../assest/newLogo.png";
import { ROOT, SENSOR_BOX } from "../../Util/routes";
import Icon from "../../Component/Icon/Icon";
import { removeInfoLocalStorage } from "../../Store/localStoreageManager/localStoreageManager";
import * as route from "../../Util/routes";
import * as actionType from "../../Store/actions/index";
import Controller from "../../Controller";

class Header extends Component {
  state = {
    selectBy: "",
    searchText: "",
    userName: "",
    avatar: "",
    email: "",
    role: "",
    isPhone: false,
    isTablet: false,
    isDesktop: true,
    showOverlay: false,
    loading: false,
  };
  componentDidMount() {
    let user = Controller.getBasicInfo();
    if (user != null) {
      this.setState({
        userName: user.firstName,
        avatar: user.avatar,
        email: user.email,
        role: user.role,
        phone: user.phone,
        account_id: user.account_id,
      });
    }
    this.handleResize();
  }
  handleResize = (e) => {
    let isPhone = false;
    let isTablet = false;
    let isDesktop = this.state.isDesktop;
    let screenWidth = window.innerWidth;
    if (screenWidth <= 640) {
      isPhone = true;
      isDesktop = false;
      this.props.setMenuOverlaySelector(true);
    }
    if (screenWidth <= 1024 && screenWidth >= 641) {
      isTablet = true;
      isDesktop = false;
    }
    this.setState(
      { isPhone: isPhone, isTablet: isTablet, isDesktop: isDesktop },
      () => {}
    );
  };
  isMacOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (
      macosPlatforms.indexOf(platform) !== -1 ||
      iosPlatforms.indexOf(platform) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };
  getAvatarImage = () => {
    if (this.state.avatar === "" || this.state.avatar == undefined) {
      return (
        <span className={styles.iconAvatar}>
          <Icon defination={{ prefix: "far", iconName: "user-circle" }} />
        </span>
      );
    } else {
      return (
        <Fragment>
          <img
            src={this.state.avatar}
            className={styles.imgAvatar}
            alt="User"
          />
        </Fragment>
      );
    }
  };
  onClickLogoutHandler = () => {
    removeInfoLocalStorage();
    this.props.setAuth(false);
    this.props.history.push(route.LOGIN);
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.refershDataRx !== this.state.loading) {
      this.setState({ loading: nextProps.refershDataRx });
    }
  };
  onClickMenuBarHandler = () => {
    this.props.setMenuOverlaySelector(!this.props.menuOverlayRx);
  };
  refreshDataHandler = () => {
    if (
      (window.location.pathname == SENSOR_BOX && !this.props.triggerRx) ||
      window.location.pathname == ROOT
    ) {
      this.props.refreshDataSelector(true);
      this.setState({ loading: true });
    }
  };

  render() {
    return (
      <div className={styles.Headers}>
        <div className={styles.iconBar}>
          <span
            onClick={this.onClickMenuBarHandler}
            className={
              this.props.menuOverlayRx
                ? `${styles.menu} ${styles.transparent}`
                : styles.menu
            }
          >
            <Icon defination={{ prefix: "far", iconName: "bars" }} />
          </span>
          <span
            onClick={this.onClickMenuBarHandler}
            className={
              !this.props.menuOverlayRx
                ? `${styles.menu} ${styles.transparent}`
                : styles.menu
            }
          >
            <Icon defination={{ prefix: "far", iconName: "times" }} />
          </span>
          <span
            className={
              this.props.triggerRx
                ? `${styles.refreshIcon} ${styles.notAllowed}`
                : styles.refreshIcon
            }
            onClick={this.refreshDataHandler}
          >
            <span className={styles.toolTip}>Refresh</span>
            <Icon
              defination={{ prefix: "far", iconName: "sync-alt" }}
              isSpinner={this.state.loading}
            />
          </span>
        </div>
        <div className={styles.btnWrapper}>
          {this.state.isPhone && (
            <>
              <span
                className={styles.iconLink}
                onClick={() =>
                  this.props.history.push(
                    window.location.pathname == ROOT ? SENSOR_BOX : ROOT
                  )
                }
              >
                {window.location.pathname != ROOT ? (
                  <Icon defination={{ prefix: "fas", iconName: "sensor" }} />
                ) : (
                  <Icon defination={{ prefix: "fas", iconName: "sensor-on" }} />
                )}
              </span>
            </>
          )}
          {!this.state.isPhone && (
            <>
              <span
                className={
                  window.location.pathname == ROOT
                    ? `${styles.link} ${styles.activeLink}`
                    : styles.link
                }
                onClick={() => this.props.history.push(ROOT)}
              >
                Sensor-BLE
              </span>
              <span
                className={
                  window.location.pathname == SENSOR_BOX
                    ? `${styles.link} ${styles.activeLink}`
                    : styles.link
                }
                onClick={() => this.props.history.push(SENSOR_BOX)}
              >
                Sensor-Box
              </span>
            </>
          )}
        </div>
        <div
          className={
            this.isMacOS()
              ? `${styles.accHeaders} ${styles.accHeadermargin}`
              : styles.accHeaders
          }
        >
          <div
            className={styles.logo}
            onClick={() => this.props.history.push(ROOT)}
          >
            <img className={styles.imgLogo} src={Logo} alt="logo-hiSky" />
          </div>
          <nav className={styles.dropdownMenu}>
            <ul>
              <li>
                <div className={styles.welcomeTitleWrapper}>
                  <div className={styles.welcomeWrapper}>
                    <span className={styles.titleWelcome}>welcome</span>
                    <span className={styles.titleWelcome}>{`${
                      this.state.userName ? this.state.userName : ""
                    }`}</span>
                  </div>
                  <span className={styles.arrowIcon}>
                    <Icon defination={{ prefix: "fal", iconName: "cog" }} />
                  </span>
                </div>

                <ul>
                  <div className={styles.generalInfo}>
                    {this.getAvatarImage()}
                    <div className={styles.infoUser}>
                      <span>
                        {this.state.userName !== "" &&
                        this.state.userName !== undefined
                          ? `${this.state.userName}`
                          : "N/A"}
                      </span>
                      <span style={{ textTransform: "initial" }}>
                        {this.state.email}
                      </span>
                    </div>
                  </div>
                  <hr className={styles.breakLine} />
                  <div className={styles.actionLinkWrapper}>
                    <span
                      className={styles.item}
                      onClick={this.onClickLogoutHandler}
                    >
                      Logout
                    </span>
                  </div>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    menuOverlayRx: state.common.menuOverlayRx,
    refershDataRx: state.common.refershDataRx,
    triggerRx: state.common.triggerRx,
    //macIdRx: state.common.macIdRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
    setMenuOverlaySelector: (data) =>
      dispatch(actionType.setMenuOverlaySelector(data)),
    refreshDataSelector: (data) =>
      dispatch(actionType.refreshDataSelector(data)),
  };
};

export default connect(mapStateProps, mapStateDispatch)(withRouter(Header));
