import { requestApi } from "./RequestManager";

export const httpRequest = (apiBuilderCallback, data) => {
  return requestApi(apiBuilderCallback(data))
    .then(res => {
  
      return Promise.resolve(res.data);
    })
    .catch(err => {
      return Promise.reject(err.data);
    });
};
