import React, { Component } from "react";
import styles from "./Dashboard.module.scss";
import DataInfo from "../../Component/DataInfo/DataInfo";
import SearchBar from "../SearchBar/SearchBar";
import MulitiChart from "../MulitiChart/MulitiChart";
import Doughnuts from "../../Component/Doughnuts/Doughnuts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  GetSensorDataRequest,
  getSensorListAndData,
} from "../../RequestManager/GqlBuilderSensor";
import * as actionType from "../../Store/actions/index";
class DashBoard extends Component {
  timer = 0;
  _isMounted = true;
  state = {
    macId: null,
    data: {},
    sensorList: [],
    activeCount: 0,
    inactiveCount: 0,
    listLoading: false,
    loading: false,
    isPhone: false,
    isTablet: false,
    isDesktop: true,
    hiskyId: "",
    changeTempMode: false,
    activeSensorsLimit: "all",
    inactiveSensorsLimit: "all",
  };
  componentWillMount() {
    this.handleResize();
  }
  componentDidMount() {
    this.createHttpRequestHandler(this.state.macId);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this._isMounted = false;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.refershDataRx) {
      this.createHttpRequestHandler(this.state.macId);
    }
    if (nextProps.menuOverlayRx && this.state.isPhone) {
      document.body.classList.add("noScrollBody");
    } else {
      document.body.classList.remove("noScrollBody");
    }
  }

  createHttpRequestHandler = (data = null) => {
   
    let dataParams = {};
    if (this.state.activeSensorsLimit != "all") {
      dataParams.limit_active = parseInt(this.state.activeSensorsLimit);
    }
    if (this.state.inactiveSensorsLimit != "all") {
      dataParams.limit_inactive = parseInt(this.state.inactiveSensorsLimit);
    }
    if (this._isMounted) {
      this.setState({ loading: true }, () => {
        if (data == null) {
          httpRequest(GetSensorDataRequest, dataParams)
            .then((res) => {
              this.props.refreshDataSelector(false);
              this.checkResponeListHandler(res.data);
            })
            .catch((err) => {});
        } else if (data !== null) {
          
          dataParams.mac_address = typeof data === "object" ? data.macId : data;
          dataParams.hisky_id= typeof data === "object" ? data.hiskyId:this.state.hiskyId
          httpRequest(getSensorListAndData, dataParams)
            .then((res) => {
              this.props.refreshDataSelector(false);
              this.checkResponeHandler(
                res.data,
                typeof data === "object" ? data.macId : data,
                typeof data === "object" ? data.hiskyId :this.state.hiskyId
              );
            })
            .catch((err) => {});
        }
      });
    }
  };
  checkResponeListHandler = (data) => {
  
    this.setState(
      {
        sensorList: data.sensorData.active.concat(data.sensorData.inactive),
        activeCount:
          data.sensorData.active !== null ? data.sensorData.active.length : 0,
        inactiveCount:
          data.sensorData.inactive != null
            ? data.sensorData.inactive.length
            : 0,
        loading: false,
      },
      () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.createHttpRequestHandler(this.state.macId);
        }, 5000);
      }
    );
  };
  handleResize = (e) => {
    let isPhone = false;
    let isTablet = false;
    let isDesktop = this.state.isDesktop;
    let screenWidth = window.innerWidth;
    if (screenWidth <= 640) {
      isPhone = true;
      isDesktop = false;
      this.props.setMenuOverlaySelector(true);
    }
    if (screenWidth <= 1024 && screenWidth >= 641) {
      isTablet = true;
      isDesktop = false;
    }
    this.setState(
      { isPhone: isPhone, isTablet: isTablet, isDesktop: isDesktop },
      () => {}
    );
  };

  changeTemparatureModeHandler = () => {
    if (!this.state.loading && this.checkDataHandler()) {
      this.setState({ changeTempMode: !this.state.changeTempMode });
    }
  };
  getDougnutsObjectHandler = () => {
    if (this.checkDataHandler()) {
      return {
        labels: ["inactive", "active"],
        datasets: [
          {
            borderWidth: 0,
            data: [100],
            backgroundColor: ["#FF6384", "#00B6C9"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            radius: "30%",
          },
        ],
        text: "",
      };
    } else {
      return {
        labels: ["inactive"],
        datasets: [
          {
            borderWidth: 0,
            data: [100],
            backgroundColor: ["#eae9e9"],
            hoverBackgroundColor: ["#eae9e9", "#eae9e9"],
            radius: "30%",
          },
        ],
        text: "",
      };
    }
  };

  calcRssiObjectDataHandler = () => {
    let data_s = this.getDougnutsObjectHandler();
    const { data } = this.state;
    if (Object.keys(this.state.data).length > 0) {
      if (parseInt(data.sensorRssi.lastSensorData.rssi) > 0) {
        data_s.text =
          data.sensorRssi.lastSensorData.rssi !== null
            ? Number.parseFloat(data.sensorRssi.lastSensorData.rssi).toFixed(2)
            : "N/A";

        let activeRssi = parseInt(data.sensorRssi.lastSensorData.rssi).toFixed(
          2
        );
        data_s.datasets[0].data = [150 - activeRssi, activeRssi];
        return data_s;
      } else {
        data_s.text = Number.parseFloat(
          data.sensorRssi.lastSensorData.rssi
        ).toFixed(2);
        data_s.datasets[0].data = [150];
        return data_s;
      }
    } else {
      return data_s;
    }
  };
  calcBatteryHandler = () => {
    let data_s = this.getDougnutsObjectHandler();
    const { data } = this.state;
    if (Object.keys(data).length > 0 && data.sensorBattery !== null) {
      data_s.text =
        data.sensorBattery.lastSensorData.battery !== null
          ? data.sensorBattery.lastSensorData.battery.toFixed(2)
          : "N/A";
      let activeBattery =
        data.sensorBattery.lastSensorData.battery !== null
          ? parseFloat(data.sensorBattery.lastSensorData.battery)
          : 0;
      data_s.datasets[0].data = [activeBattery - 3.4, activeBattery];
      return data_s;
    } else {
      return data_s;
    }
  };
  calcBooleanDataHandler = (obj, key) => {
    let data_s = this.getDougnutsObjectHandler();
    const { data } = this.state;
    if (Object.keys(data).length > 0 && data[obj.toString()] !== null) {
      data_s.text = data[obj.toString()].lastSensorData[key.toString()]
        ? "Active"
        : "Inactive";

      data_s.datasets[0].backgroundColor = data[obj.toString()].lastSensorData[
        key.toString()
      ]
        ? ["#00B6C9"]
        : ["#FF6384"];
      return data_s;
    } else {
      return data_s;
    }
  };
  calcLightHandler = () => {
    let data_s = this.getDougnutsObjectHandler();
    const { data } = this.state;

    if (Object.keys(data).length > 0 && data.sensorLight !== null) {
      data_s.text =
        data.sensorLight.lastSensorData.light !== null
          ? data.sensorLight.lastSensorData.light.toFixed(2)
          : "N/A";
      let activeBattery =
        data.sensorLight.lastSensorData.light !== null
          ? parseInt(data.sensorLight.lastSensorData.light)
          : 0;
      data_s.datasets[0].data = [100 - activeBattery, activeBattery];
      return data_s;
    } else {
      return data_s;
    }
  };

  checkResponeHandler = (data, macId, hiskyId) => {
    this.setState(
      {
        data: data,
        sensorList: data.sensorData.active.concat(data.sensorData.inactive),
        activeCount: data.sensorData.active.length,
        inactiveCount: data.sensorData.inactive.length,
        loading: false,
        macId: macId,
        hiskyId:
          hiskyId !== "" && hiskyId !== null && hiskyId !== this.state.hiskyId
            ? hiskyId
            : this.state.hiskyId,
      },
      () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.createHttpRequestHandler(this.state.macId);
        }, 5000);
      }
    );
  };
  checkDataHandler = () => {
    return Object.keys(this.state.data).length > 0 ? true : false;
  };
  limitsChangedHandler = (data) => {
    this.setState(
      {
        activeSensorsLimit: data.activeSensorsLimit,
        inactiveSensorsLimit: data.inactiveSensorsLimit,
        macId:null,
        hiskyId:'',
        data:{}
      },
      () => {
        //clearTimeout(this.timer);
        this.createHttpRequestHandler();
      }
    );
  };
  render() {
    const { data } = this.state;

    return (
      <div className={styles.Dashboard}>
        <div
          className={
            this.props.menuOverlayRx
              ? `${styles.overlay} ${styles.searchWrapper}`
              : styles.searchWrapper
          }
        >
          <SearchBar
            sensorsList={this.state.sensorList}
            activeCount={this.state.activeCount}
            inactiveCount={this.state.inactiveCount}
            isPhone={this.state.isPhone}
            isTablet={this.state.isTablet}
            isDesktop={this.state.isDesktop}
            macSelected={(macId) => this.createHttpRequestHandler(macId)}
            limitsChanged={(data) => this.limitsChangedHandler(data)}
          />
        </div>

        <div
          className={styles.dataWrapperInfo}
          style={{
            opacity: this.state.loading || !this.checkDataHandler() ? 1 : 1,
          }}
        >
          <>
            <div className={styles.chartWrapper}>
              <MulitiChart
                loading={this.state.loading}
                style={styles.chart}
                tempData={
                  Object.keys(this.state.data).length > 0
                    ? this.state.data.sensorTemperature30.sensorData
                    : []
                }
                disableAspectRatio={false}
                macId={this.state.macId+this.state.hiskyId}
                batData={
                  Object.keys(this.state.data).length > 0
                    ? this.state.data.sensorBattery30.sensorData
                    : []
                }
                humidData={
                  Object.keys(this.state.data).length > 0
                    ? this.state.data.sensorHumidity30.sensorData
                    : []
                }
                changeTempMode={this.state.changeTempMode}
              />
            </div>
            <div className={styles.doughnutsWrapper}>
              <Doughnuts
                disableAspectRatio={true}
                data={this.calcRssiObjectDataHandler()}
                title="RSSI"
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
              <Doughnuts
                disableAspectRatio={true}
                data={this.calcBatteryHandler()}
                title="Battery"
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
              <Doughnuts
                data={this.calcLightHandler()}
                title="Light Sensor"
                disableAspectRatio={true}
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
              <Doughnuts
                data={this.calcBooleanDataHandler("sensorMagnet", "magnet")}
                title="Magent"
                disableAspectRatio={true}
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
              <Doughnuts
                data={this.calcBooleanDataHandler("sensorMovement", "movement")}
                title="Movement"
                disableAspectRatio={true}
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
              <Doughnuts
                data={this.calcBooleanDataHandler("sensorFreefall", "freefall")}
                title="Free Fall"
                disableAspectRatio={true}
                style={styles.singelDoughnut}
                loading={this.state.loading && this.state.macId != null}
              />
            </div>

            <div className={styles.dataWrapperContianers}>
              <DataInfo
                title="RSSI"
                amount={
                  Object.keys(data).length && data.sensorRssi != null
                    ? data.sensorRssi.lastSensorData.rssi
                    : ""
                }
                key_data="rssi"
                list_data={
                  this.checkDataHandler() ? data.sensorRssi.sensorData : []
                }
                loading={this.state.loading && this.state.macId != null}
                icon={{ prefix: "fal", iconName: "signal-stream" }}
              />
              <DataInfo
                title="Light Sensor"
                amount={
                  Object.keys(data).length && data.sensorLight !== null
                    ? data.sensorLight.lastSensorData.light
                    : ""
                }
                key_data="light"
                list_data={
                  this.checkDataHandler() ? data.sensorLight.sensorData : []
                }
                icon={{ prefix: "fal", iconName: "lightbulb" }}
                loading={this.state.loading && this.state.macId != null}
              />
              <DataInfo
                title="Magent"
                key_data="magnet"
                amount={
                  Object.keys(data).length && data.sensorMagnet !== null
                    ? data.sensorMagnet.lastSensorData.magnet
                    : ""
                }
                list_data={
                  this.checkDataHandler() ? data.sensorMagnet.sensorData : []
                }
                icon={{ prefix: "fal", iconName: "magnet" }}
                loading={this.state.loading && this.state.macId != null}
              />
              <DataInfo
                title="Click"
                key_data="click"
                amount={
                  Object.keys(data).length && data.sensorClick !== null
                    ? data.sensorClick.lastSensorData.click
                    : ""
                }
                list_data={
                  this.checkDataHandler() ? data.sensorClick.sensorData : []
                }
                icon={{ prefix: "far", iconName: "mouse-alt" }}
                loading={this.state.loading && this.state.macId != null}
              />
              <DataInfo
                title="Movement"
                key_data="movement"
                amount={
                  Object.keys(data).length && data.sensorMovement != null
                    ? data.sensorMovement.lastSensorData.movement
                    : ""
                }
                list_data={
                  this.checkDataHandler() ? data.sensorMovement.sensorData : []
                }
                icon={{ prefix: "fal", iconName: "walking" }}
                loading={this.state.loading && this.state.macId != null}
              />
              <DataInfo
                key_data="freefall"
                title="Free Fall"
                amount={
                  Object.keys(data).length && data.sensorFreefall !== null
                    ? data.sensorFreefall.lastSensorData.freefall
                    : ""
                }
                list_data={
                  this.checkDataHandler() ? data.sensorFreefall.sensorData : []
                }
                icon={{ prefix: "far", iconName: "chevron-square-down" }}
                loading={this.state.loading && this.state.macId != null}
              />
            </div>
          </>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    menuOverlayRx: state.common.menuOverlayRx,
    refershDataRx: state.common.refershDataRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
    setMenuOverlaySelector: (data) =>
      dispatch(actionType.setMenuOverlaySelector(data)),
    refreshDataSelector: (data) =>
      dispatch(actionType.refreshDataSelector(data)),
  };
};
export default connect(mapStateProps, mapStateDispatch)(withRouter(DashBoard));
