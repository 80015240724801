import { isLocalhost, getHost } from "./Util/browser";

// API section
const TIMEOUT = 80000;
const HEADERS = {};

const PROTO = isLocalhost() ? "https" : "https";
//const HOST = isLocalhost() ? 'dev.hiskysat.com' : getHost();
const HOST = isLocalhost() ? 'sensordemo.com' : "sensordemo.com";
const API_URL = `${PROTO}://${HOST}:8000/api/graphql`;
const EXPORTS_URL = `${PROTO}://${HOST}:8000/static`
export default Object.freeze({
  api: {
    API_URL,
    TIMEOUT,
    HEADERS,
    EXPORTS_URL
  },
});
