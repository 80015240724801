import axios from "axios";
import Config from "../config";
import { getLocalStorageInfo } from "../Store/localStoreageManager/localStoreageManager";

export const requestApi = data => {
  let TOKEN = getLocalStorageInfo();
  return axios
    .request({
      method: "post",
      data: data,
      url: Config.api.API_URL,
      headers: {
        Authorization: TOKEN ? `JWT ${TOKEN}` : null
      },
       params: data.params !==undefined ? data.params :null
    })
    .then(res => {
      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
