import React, { Component } from "react";
import { Doughnut, Chart } from "react-chartjs-2";
import styles from "./Doughnuts.module.scss";
import Icon from "../Icon/Icon";

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;
    var fontSize = (height /110).toFixed(2);
    ctx.font = fontSize + "em Poppins";
    ctx.textBaseline = "middle";
    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);    
  },
});

class Doughnuts extends Component {
 
  render() {
    let options = {
      responsive: true,
      animateRotate: true,
      maintainAspectRatio: true,
      legend: {
        position: "bottom",
        display: false,
      },
      layout: {
        padding: 20,
      },
      cutoutPercentage: 70,
      tooltips: {
        enabled: Object.keys(this.props.data).length && this.props.data.text!='' ? true: false
      }
    };

    return (
      <div className={this.props.style}>
        {/* <p className={styles.title}>{this.props.title}</p> */}
        <div className={styles.titleWrapper}>
          
          <p className={styles.title}>{this.props.title}</p>
          <span
            className={styles.spinnerIcon}
            style={{
              visibility: !this.props.loading ? "hidden" : "visible",
            }}
          >
            <Icon
              isSpinner={true}
              defination={{ prefix: "fal", iconName: "spinner" }}
            />
          </span>
        </div>
        <Doughnut
          width={window.screen.width > 1200 ? 30 : 25}
          height={window.screen.width > 1200 ? 20 : 15}
          options={options}
          data={this.props.data}
        />
      </div>
    );
  }
}

export default Doughnuts;
