import React, { Component } from 'react'
import styles from "./Spinner.module.scss"
import LOGO from "../../assest/logo.png"
export default class Spinner extends Component {
    render() {
        return (
            <div className={styles.Spin}>
                <div className={styles.InfoWrapper}>
                    <div className={styles.imgWrapper}>
                        <img src={LOGO} alt="logo-hiSky" className={styles.img}/>
                    </div>
                    
                    <p>Loading .......</p></div>
            </div>
        )
    }
}
