import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  isAuth: null,
  refershDataRx: false,
  menuOverlayRx: false,
  macIdRx: null,
  triggerRx: false,
  historySensorRx: false,
  model: false,
  macHistoryRx: "",
  configRX: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_AUTH:
      return updateObject(state, { isAuth: action.payload });
    case actionType.REFRESH_DATA:
      return updateObject(state, { refershDataRx: action.payload });
    case actionType.SET_MENU_OVERLAY:
      return updateObject(state, { menuOverlayRx: action.payload });
    case actionType.SET_MAC_ID:
      return updateObject(state, { macIdRx: action.payload });
    case actionType.SET_TRIGGER_MODE:
      return updateObject(state, { triggerRx: action.payload });
    case actionType.OPEN_HISTORY:
      return updateObject(state, {
        model: !state.model,
        historySensorRx: true,
      });
    case actionType.OPEN_CONFIG:
      return updateObject(state, { model: !state.model, configRX: true });
    case actionType.RESET_MODEL:
      return updateObject(state, {
        model: false,
        historySensorRx: false,
        configRX: false,
      });
    case actionType.UPLOAD_MAC:
      return updateObject(state, { macHistoryRx: action.payload });
    default:
      return state;
  }
};

export default reducer;
