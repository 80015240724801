import React, { Component } from "react";
import styles from "./Gallery.module.scss";
export default class Gallery extends Component {
  render() {
    return (
      <div className={styles.Galleries}>
        <div className={styles.infoGallery}>
          <h3 className={styles.title}>Our vision</h3>
          <p className={styles.paragraph}>
            We believe that if the technology to connect people in the most
            remote corners of the world already exists, then it should be
            available to everyone. So, we’ve raised the stakes and made it not
            only quick, easy and reliable, but also affordable enough that you
            don’t have to think twice before making a call.
          </p>
      
        </div>
      </div>
    );
  }
}
