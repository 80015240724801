import React, { Component } from "react";
import Gallery from "./Gallery/Gallery";
import styles from "./Login.module.scss";
import Input from "../../Component/Input/Input";
import Icon from "../../Component/Icon/Icon";
import SingleButton from "../../Component/SingleButton/SingleButton";
import Logo from "../../assest/logo.png";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  buildLoginRequest,
  requestResetPassword,
  resetSensorDataRequest
} from "../../RequestManager/GqlBuilderUser";
import Controller from "../../Controller";
import { connect } from "react-redux";
import { storeInLocalStorage } from "../../Store/localStoreageManager/localStoreageManager";
import * as actionType from "../../Store/actions/index";

class Login extends Component {
  state = {
    validUser: false,
    loading: false,
    sucssesMsg:"",
    errorMsg: "",
    resetpass: false,
    user: {
      email: {
        value: "",
        error: false,
        errMsg: "*The email is not valid",
      },
      password: {
        value: "",
        error: false,
        type: "password",
        errMsg: "*The password is not valid",
      },
      emailReset: {
        value: "",
        error: false,
        errMsg: "*The email is not valid",
      },
    },
  };
  checkValidData = () => {
    let isValid = true;
    let copyUser = { ...this.state.user };
    if (!this.state.resetpass) {
      if (copyUser.email.value === "") {
        copyUser.email.error = true;
        isValid = false;
      }
      if (copyUser.password.value === "") {
        copyUser.password.error = true;
        isValid = false;
      }

      this.setState({ user: copyUser });
      return isValid;
    } else {
      if (copyUser.emailReset.value === "") {
        copyUser.emailReset.error = true;
        this.setState({ user: copyUser });
        isValid = false;
      }
      return isValid;
    }
  };
  onChangeHandler = (event) => {
    if (this.state.loading) return;
    let copyUser = { ...this.state.user };
    copyUser[event.target.name].value = event.target.value.trim();
    copyUser[event.target.name].error = false;
    this.setState({ user: copyUser, validUser: false, errorMsg: "" });
  };
  checkResponeHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const { login } = res.data;
      let info = {
        account_id: login.user.account.id,
        lastName: login.user.lastName,
        firstName: login.user.firstName,
        userId: login.user.id,
        email: login.user.email,
        avatar: login.user.avatar,
        parentIds: login.user.account.parentIds,
        role: `${login.user.account.entity.name}-${login.user.role.name}`,
        entity_name: login.user.account.entity.name,
        phone: login.user.phone,
      };

      storeInLocalStorage(
        JSON.stringify({
          token: login.token,
        })
      );
      Controller.setAccountInfo(info);
      this.props.setAuth(true);
      this.props.history.push("/");
    }
  };
  createHttpRequestHandler = () => {
    const { user } = this.state;
    if (!this.state.resetpass) {
      httpRequest(buildLoginRequest, {
        user_name: user.email.value.toLowerCase(),
        password: user.password.value,
      })
        .then((res) => {
          console.log(res);
          return Promise.resolve(this.checkResponeHandler(res));
        })
        .catch((err) => {});
    }else {
      httpRequest(requestResetPassword, {
        email: this.state.user.emailReset.value.toLowerCase(),
        continueTo:encodeURIComponent(document.location.host)
      })
        .then((res) => {
          this.checkResponedOfServerHandler(res);
        })
        .catch((err) => {});
    }
  };
  onClickLoginHandler = (event) => {
    event.preventDefault();
    if (this.checkValidData()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.createHttpRequestHandler();
        }
      );
    } 
  };
  checkResponedOfServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      this.setState({
        loading: false,
        validUser: true,
        sucssesMsg: "we have e-mail your password reset link",
      });
    }
  };
  onClickResetPassHandler = () => {
    this.setState({ resetpass: !this.state.resetpass });
  };
  changeVisiblePasswordHandler = () => {
    let user = { ...this.state.user };
    user["password"].type =
      user["password"].type === "password" ? "text" : "password";
    this.setState({ user: user });
  };
  render() {
    const { password, email, emailReset } = this.state.user;
    return (
      <div className={styles.Login}>
        <Gallery />
        <div className={styles.formLogin}>
          <form onSubmit={this.onClickLoginHandler}>
            <div className={styles.logo}>
              <img className={styles.photo} src={Logo} alt="logo-hi-sky" />
            </div>

            <h4 className={styles.secondHeader}>Welcome back</h4>
            <p className={styles.msg}>
              Please sign into your account{" "}
              <span
                className={styles.spinnerIcon}
                style={{
                  visibility: !this.state.loading ? "hidden" : "visible",
                }}
              >
                <Icon
                  isSpinner={true}
                  defination={{ prefix: "fal", iconName: "spinner" }}
                />
              </span>
            </p>

            <div className={styles.breakLine}></div>
            <p
              className={styles.error}
              style={{
                visibility: this.state.validUser ? "visible" : "hidden",
              }}
            >
              {this.state.errorMsg}
            </p>
            <div className={styles.inputGroup}>
              {!this.state.resetpass ? (
                <>
                  <Input
                    name="email"
                    inputWrapper={styles.inputWrapper}
                    placeholder="Enter email"
                    label="User name"
                    error={email.error}
                    errMsg={email.errMsg}
                    change={(e) => this.onChangeHandler(e)}
                    value={email.value}
                  />
                  <Input
                    name="password"
                    inputWrapper={styles.inputWrapper}
                    placeholder="Enter password"
                    label="Password"
                    type={this.state.user.password.type}
                    error={password.error}
                    errMsg={password.errMsg}
                    change={(e) => this.onChangeHandler(e)}
                    value={password.value}
                    iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                    iconStyles={styles.visiblePassword}
                    iconAction={this.changeVisiblePasswordHandler}
                  />
                </>
              ) : (
                <Input
                  name="emailReset"
                  inputWrapper={styles.inputWrapper}
                  placeholder="*email here..."
                  label="Email"
                  type={this.state.user.emailReset.type}
                  error={emailReset.error}
                  errMsg={emailReset.errMsg}
                  change={(e) => this.onChangeHandler(e)}
                  value={emailReset.value}
                />
              )}
            </div>
            <p className={styles.resetLinkMsg}>{this.state.sucssesMsg}</p>
            <div className={styles.breakLine}></div>
            <div className={styles.resetPassWrapper}>
              {!this.state.resetpass ? (
                <p
                  className={styles.msgPass}
                  onClick={this.onClickResetPassHandler}
                >
                  forgot password
                </p>
              ) : (
                <p
                  className={styles.msgPass}
                  onClick={this.onClickResetPassHandler}
                >
                  <span className={styles.goBackIcon}>
                    <Icon
                      defination={{
                        prefix: "fal",
                        iconName: "long-arrow-left",
                      }}
                    />
                  </span>
                  Go Back
                </p>
              )}
            </div>
            <div className={styles.btnSubmitControl}>
              <SingleButton
                text={!this.state.resetpass ? "login" : "reset password"}
                click={this.onClickLoginHandler}
                disabled={this.state.loading}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};
const mapDispatchProps = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
  };
};
export default connect(mapStateProps, mapDispatchProps)(Login);
