////////////For auth Action types //////////////////////////////////////
export const SET_AUTH = "set auth";
export const SET_MENU_OVERLAY = "Set menu overlay";
export const REFRESH_DATA = "refresh data";
export const SET_MAC_ID = "set mac id";
export const SET_TRIGGER_MODE = "set trigger mode";
export const RESET_MODEL = "rest model";
export const OPEN_HISTORY = "open history";
export const UPLOAD_MAC="upload mac"
export const OPEN_CONFIG="open config"
