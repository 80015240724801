import React, { Component, Fragment } from "react";
import Icon from "../Icon/Icon";
import styles from "./ProgressBar.module.scss";
class ProgressBar extends Component {
  setBarHandler = () => {
    let bar = [];
    for (let i = 0; i < 100; i++) {
      bar.push(
        <span
          className={styles.line}
          style={{
            border:
              this.props.value !== undefined &&
              this.props.value !== "" &&
              i <= Math.round(this.props.value / 10)
                ? "1px solid #00bdd6"
                : "",
          }}
        ></span>
      );
    }
    return bar.map((item, index) => {
      return <Fragment key={index}>{item}</Fragment>;
    });
  };
  render() {
    return (
      <div className={styles.ProgressBars}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{this.props.title}</p>
          <span className={styles.icon}>
            <Icon defination={{ prefix: "fas", iconName: this.props.title.toLowerCase() ==='light' ? "lightbulb-on": "waveform" }} />
          </span>
        </div>
        <div className={styles.barwrapper}>
          <div className={styles.dataInfo}>{this.props.value}</div>
          <div className={styles.bar}> {this.setBarHandler()}</div>
          <div className={styles.dataInfo}>
            <span className={styles.count}>0</span>
            <span className={styles.count}>1000</span>
            {this.props.subtitle}
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
