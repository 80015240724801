import React, { Component, Fragment } from "react";
import styles from "./RotateSpier.module.scss";
import LOGO from "../../assest/logo.png";
class RotateSpier extends Component {
  getFillColorHandler = (index) => {
    if (this.props.value === undefined || this.props.value === "") {
      return `linear-gradient(90deg, #fff 50%, #eeeeee 50%)`;
    } else {
      return Math.round(this.props.value) / 2 >= index
        ? `linear-gradient(90deg, #fff 50%, #00bdd6 50%)`
        : `linear-gradient(90deg, #fff 50%, #eeeeee 50%)`;
    }
  };
  getLinesHandler = () => {
    let temp = [];
    var rotation = 0;
    for (let i = 0; i < 180; i++) {
      temp.push(
        <span
          className={styles.radius}
          style={{
            transform: `rotate(${rotation}deg)`,
            background: this.getFillColorHandler(i),
          }}
        ></span>
      );
      rotation -= 2;
    }
    return temp.map((item, index) => {
      return <Fragment key={index}>{item}</Fragment>;
    });
  };

  render() {
    return (
      <div className={styles.Rotatespier}>
        <p className={styles.textDisc}>Rotation</p>
        <div className={styles.contentWrapper}>
          <div className={styles.circleContainer}>
            <div>{this.getLinesHandler()}</div>
            <div className={styles.title}>
              {this.props.value === "" ? "N/A" : this.props.value+String.fromCharCode(0xb0)}
            </div>
          </div>
          <div className={styles.logo}>
            <img className={styles.imgLogo} src={LOGO} alt="logo-hiSky" />
          </div>
        </div>
      </div>
    );
  }
}

export default RotateSpier;
