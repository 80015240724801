import React, { Component } from "react";
import styles from "./Input.module.scss";
import Icon from '../Icon/Icon';
export default class Input extends Component {
  render() {
    const {
      type,
      label,
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      blur,
      disabled,
      id,
      min,
      onFoucs,
      autocomplete,
      step,
      hideError = false,
      isRegularText = false,
      inputStyles = {},
      iconStyles = {},
      iconDefination = {},
      format
    } = this.props;

    return (
      <div className={inputWrapper}>
        <label className={isRegularText ? styles.regularLabel : styles.label}>
          {label}
        </label>
        <input
          name={name}
          className={styles.input}
          type={type}
          onChange={this.props.change}
          value={value}
          id={id}
          min={min}
          step={step}
          
          autoComplete={!autocomplete ? "on" : "off"}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={blur}
          onFocus={onFoucs}
          style={inputStyles}
        />
        {Object.keys(iconDefination).length>0 && (
          <span className={iconStyles} onClick={this.props.iconAction}>
            <Icon defination={iconDefination} />
          </span>
        )}
        {!hideError && (
          <p
            className={styles.error}
            style={{ visibility: error ? "visible" : "hidden" }}
          >
            {errMsg}
          </p>
        )}
      </div>
    );
  }
}
