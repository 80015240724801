import React, { Component } from "react";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import styles from "./LightSwitch.module.scss";
class LightSwitch extends Component {
  state = {
    status: true,
    loading: false,
  };
  onChangeColorHandler = (event) => {    
    this.props.click();
    var sound = document.getElementById("audioSwitch");
    sound.play();
  };

  render() {
    return (
      <>
        <audio
          id="audioSwitch"
          src="https://res.cloudinary.com/hisky/video/upload/v1600355261/b6ypvl9rpyqjbtiaxcju.mp3"
          autostart="false"
        ></audio>
        <div
          className={
            this.props.loading || this.props.noData || !this.props.isActive
              ? `${styles.LightSwitchs} ${styles.pointerEvent}`
              : styles.LightSwitchs
          }
        >
          <div
            className={styles.light}
            style={{ opacity: this.props.loading ? 0.6 : 1 }}
          >
            <p className={styles.title}>{this.props.ledColor + " Led"}</p>
            <span
              className={styles.circle}
              style={{
                backgroundColor: !this.props.value
                  ? "black"
                  : this.props.ledColor,
              }}
            ></span>
          </div>
          <div className={styles.switchWrapper}>
            <div className={styles.round}>
              <input
                type="checkbox"
                id={this.props.id}
                name="onoff"
                checked={!this.props.value}
                value={!this.props.value}
                onChange={(e) => this.onChangeColorHandler(e)}
                className={styles.inputCheckbox}
              />
              <div className={styles.back}>
                <label className={styles.but} htmlFor={this.props.id}>
                  <span className={styles.on}>I</span>
                  <span className={styles.off}>0</span>
                </label>
              </div>
              <div
                className={styles.spinnerWrapper}
                style={{ display: this.props.loading ? "block" : "none" }}
              >
                <SpinnerLoader />
              </div>
            </div>
          </div>
          <p
            className={styles.msg}
            style={{ visibility: this.props.loading ? "visible" : "hidden" }}
          >
            The command has been sent
          </p>
         
        </div>
      </>
    );
  }
}

export default LightSwitch;
