import * as route from "./Util/routes";
class Controller {
  instance = Controller.instance || new Controller();

  static setAccountInfo(dataSet) {
    let data = 'hello'
    this.instance = {
      accountDetails:dataSet
    }
  } 
 static getBasicInfo = () => {
    let data = this.instance;
    if (data == null) {
      return null;
    } else {
      return {
        firstName: data.accountDetails.firstName,
        lastName: data.accountDetails.lastName,
        userId: data.accountDetails.userId,
        avatar: data.accountDetails.avatar,
        email: data.accountDetails.email,
        role: data.accountDetails.role,
        account_id: data.accountDetails.account_id,
        parentIds: data.accountDetails.parentIds
      };
    }
  };
  static getAccountId() {
    let obj = { ...this.instance };
    return this.instance;
  }
}

export default Controller;
