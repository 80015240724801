import React, { Component } from "react";
import styles from "./SearchBar.module.scss";
import Icon from "../../Component/Icon/Icon";
import moment from "moment";
import SpinnerLoader from "../../Component/SpinnerLoader/SpinnerLoader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "../../Component/Pagination/Pagination";
import SelectWithOptions from "../../Component/SelectWithOptions/SelectWithOptions";
import * as actionType from "../../Store/actions/index";
import LOGO from "../../assest/logo.png";
import SENSOR from "../../assest/ERMSensor.png";
import SENSOR_BOX_IMG from "../../assest/sensor-box.png";
import { SENSOR_BOX } from "../../Util/routes";
class SearchBar extends Component {
  isSensorPage = false;
  state = {
    search: "",
    loading: false,
    data: [],
    originalData: [],
    indexAction: 0,
    activeCount: 0,
    inactiveCount: 0,
    activeIndex: "",
    activerow: "",
    showDetails: true,
    sortDirection: {
      date: false,
    },
    sensorsLimit: [
      { id: "all", name: "All" },
      { id: 5, name: "5" },
      { id: 10, name: "10" },
      { id: 15, name: "15" },
      { id: 20, name: "20" },
    ],
    activeSensorsLimit: {
      value: "all",
      err: false,
      errMsg: "Please select  limit",
    },
    inactiveSensorsLimit: {
      value: "all",
      err: false,
      errMsg: "Please select limit",
    },
  };
  componentDidMount() {
    if (window.location.pathname == SENSOR_BOX) this.isSensorPage = true;
  }
  componentWillReceiveProps(nextprops) {
    if (
      nextprops.activeCount != this.state.activeCount ||
      nextprops.inactiveCount != this.state.inactiveCount ||
      nextprops.sensorsList.length != this.state.data.length
    ) {
      this.checkResponeHandler(nextprops);
    }
  }

  checkResponeHandler = (data) => {
    let res = this.chunDataHandler(data.sensorsList);
    this.setState({
      data: res.chunk,
      originalData: data.sensorsList,
      activeCount: data.activeCount,
      inactiveCount: data.inactiveCount,
      loading: false,
      indexAction: res.index_d,
      //activerow:''
    });
  };
  getActiveIndexHandler = (data) => {
    let index = this.state.indexAction;

    if (index === 0) return index;
    if (data[index] !== undefined) return index;
    for (index; index >= 0; index--) {
      if (data[index] !== undefined) {
        return index;
      }
    }
    return 0;
  };
  convertDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };
  onChangeHandler = (event) => {
    this.setState({ search: event.target.value });
  };
  buildTableHeader = () => {
    let headerList = ["hiSky Id", "Mac Id", "Date", "Status"];
    return headerList.map((item, index) => {
      return (
        <div
          key={index}
          className={
            item == "hiSky Id"
              ? `${styles.textCell} ${styles.genericLabel}`
              : styles.textCell
          }
        >
          <strong>{item}</strong>
          {/* {this.getIconSortBuilderHandler(item)} */}
        </div>
      );
    });
  };
  buildTableHeaderSensorBox = () => {
    let headerList = ["Mac Id", "Date", "Status"];
    return headerList.map((item, index) => {
      return (
        <div
          key={index}
          className={
            item == "hiSky Id"
              ? `${styles.textCellBox} ${styles.genericLabel}`
              : styles.textCellBox
          }
        >
          <strong>{item}</strong>
          {/* {this.getIconSortBuilderHandler(item)} */}
        </div>
      );
    });
  };
  chunDataHandler = (data_a) => {
    let index = 0;
    let size = 10;
    let data = JSON.parse(JSON.stringify(data_a));
    let chunk = [];
    while (index < data.length) {
      chunk.push(data.slice(index, size + index));
      index += size;
    }

    let index_d = this.getActiveIndexHandler(chunk);

    return { chunk, index_d };
  };
  getDetailInfoHandler = (macAddress, hiskyId = null) => {
    this.setState(
      {
        activeIndex: macAddress,
        activerow:
          this.props.history.location.pathname !== SENSOR_BOX
            ? macAddress + hiskyId
            : macAddress,
      },
      () => {}
    );

    if (this.props.history.location.pathname !== SENSOR_BOX) {
      this.props.macSelected({ macId: macAddress, hiskyId: hiskyId });
    } else this.props.macSelected({ macId: macAddress, isActive: hiskyId });

    this.props.setMenuOverlaySelector(false);
  };
  getBackroundColorHandler = (index) => {
    return index % 2 === 0 ? styles.evenRow : styles.oddRow;
  };
  getEmptyRowdataHandler = () => {
    let temp = [];
    let del = 15 - this.state.data.length;
    for (let i = 0; i < del; i++) {
      temp.push(
        <div key={i} className={styles.emptyRow}>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
          <div className={styles.textCell}>&nbsp;</div>
        </div>
      );
    }
    return temp;
  };
  converStringbySizeHandler = (str) => {
    if (str == null) return "N/A";
    if (window.location.pathname == SENSOR_BOX) {
      if (window.innerWidth < 1400 && str.length > 7) {
        return str.length > 10 ? "..." + str.substring(str.length, 10) : str;
      } else {
        return str;
      }
    } else {
      return str.length > 10 ? "..." + str.substring(str.length, 10) : str;
    }
  };
  trimHiskyId = (str) => {
    return window.innerWidth < 1400 && str.length > 7
      ? "..." + str.substring(str.length, 7)
      : str;
  };
  sortByAscendingOrderHandler = (type, key) => {
    let originalDataCopy = JSON.parse(JSON.stringify(this.state.originalData));
    let sortCopy = { ...this.state.sortDirection };
    let arr = [];
    if (type == "Date") {
      arr = originalDataCopy.sort((a, b) =>
        new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1
      );
    }
    sortCopy[key] = !this.state.sortDirection[key];
    this.setState({ loading: true, sortDirection: sortCopy }, () => {
      this.chunkArray(arr, key);
    });
  };
  sortByDescendingOrderHandler = (type, key) => {
    let sortCopy = { ...this.state.sortDirection };
    let originalDataCopy = JSON.parse(JSON.stringify(this.state.originalData));
    let arr = [];
    if (type == "Date") {
      arr = originalDataCopy.sort((a, b) =>
        new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1
      );
    }
    sortCopy[key] = !this.state.sortDirection[key];
    this.setState({ sortDirection: sortCopy, loading: true }, () => {
      this.chunkArray(arr, key);
    });
  };
  managerOnClickSortHandler = (type) => {
    let { sortDirection } = this.state;
    switch (type) {
      case "Date":
        sortDirection.date
          ? this.sortByAscendingOrderHandler(type, "date")
          : this.sortByDescendingOrderHandler(type, "date");
        break;
    }
  };
  getIconSortBuilderHandler = (item) => {
    let { sortDirection } = this.state;
    switch (item) {
      case "Date":
        return (
          <>
            <span
              className={styles.iconSort}
              onClick={() => this.managerOnClickSortHandler(item)}
            >
              {sortDirection.date ? (
                <Icon defination={{ prefix: "fas", iconName: "sort-up" }} />
              ) : (
                <Icon defination={{ prefix: "fas", iconName: "sort-down" }} />
              )}
              <span className={styles.toolTip}>
                {sortDirection.date ? "Decs" : "Asc"}
              </span>
            </span>
          </>
        );

      default:
        return;
    }
  };
  chunkArray = (data, key) => {
    let direction = this.state.sortDirection.date;
    let copyData = JSON.parse(JSON.stringify(data));
    let chunk = data.sort(function (a, b) {
      if (key == "date") {
        var keyA = new Date(a.timestamp),
          keyB = new Date(b.timestamp);
        // Compare the 2 dates
        if (!direction) {
          // DESCENDING order.
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        } else {
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
          return 0;
        }
      }
    });
    this.setState(
      {
        originalData: copyData,
        data: chunk,
        loading: false,
      },
      () => {}
    );
  };
  buildTableRow = () => {
    if (this.state.data.length === 0) return;
    return this.state.data[this.state.indexAction].map((item, index) => {
      let hiskyId = item.hiskyId == null ? "" : item.hiskyId;

      return (
        <div
          key={index}
          className={`${styles.tableRow} ${
            this.state.activerow === item.macAddress + hiskyId
              ? styles.selectSensor
              : this.getBackroundColorHandler(item.macAddress + hiskyId)
          }`}
          onClick={(macAddress) =>
            this.getDetailInfoHandler(item.macAddress, hiskyId)
          }
        >
          <div
            className={
              item.hiskyId
                ? `${styles.hiskytooltipTxt} ${styles.textCell}`
                : styles.textCell
            }
          >
            {item.hiskyId !== undefined && (
              <span
                className={
                  item.hiskyId !== undefined && item.hiskyId !== null
                    ? styles.toolTip
                    : ""
                }
              >
                {item.hiskyId !== undefined ? item.hiskyId : "N/A"}
              </span>
            )}
            {item.hiskyId !== undefined &&
            window.location.pathname != SENSOR_BOX
              ? this.converStringbySizeHandler(item.hiskyId)
              : "N/A"}
          </div>
          <div
            className={`${styles.tooltipTxt} ${styles.textCell} ${styles.genericLabel}`}
            style={{ textOverflow: "unset" }}
          >
            <span className={`${styles.toolTip} ${styles.genericLabel}`}>{item.macAddress}</span>
            {this.converStringbySizeHandler(item.macAddress)}
          </div>

          <div
            className={
              window.location.pathname != SENSOR_BOX
                ? `${styles.datetooltipTxt} ${styles.textCell}`
                : styles.textCell
            }
          >
            {window.location.pathname != SENSOR_BOX && (
              <span className={styles.toolTip}>
                {item.timestamp
                  ? this.convertDateHandler(item.timestamp)
                  : "N/A"}
              </span>
            )}
            {item.timestamp && window.location.pathname != SENSOR_BOX
              ? this.convertDateHandler(item.timestamp)
              : "N/A"}
          </div>
          <div className={styles.textCell} style={{ textAlign: "center" }}>
            <span
              className={styles.entity}
              style={{
                backgroundColor:
                  index < this.state.activeCount ? "green" : "red",
              }}
            ></span>
          </div>
        </div>
      );
    });
  };
  buildTableRowForSensorBox = () => {
    if (this.state.data.length === 0) return;
    return this.state.data[this.state.indexAction].map((item, index) => {
      return (
        <div
          key={index}
          className={`${styles.tableRow} ${
            this.state.activerow === item.macAddress
              ? styles.selectSensor
              : this.getBackroundColorHandler(item.macAddress)
          }`}
          onClick={(macAddress) =>
            this.getDetailInfoHandler(
              item.macAddress,
              index < this.state.activeCount ? true : false
            )
          }
        >
          {/* <div
            className={item.hiskyId ? `${styles.tooltipTxt} ${styles.textCellBox}` : styles.textCellBox}
            style={{ textOverflow: "unset" }}
          >
            <span className={styles.toolTip}>{item.hiskyId}</span>
            {this.converStringbySizeHandler(item.hiskyId)}
          </div> */}
          <div className={`${styles.tooltipTxt} ${styles.textCellBox} ${styles.genericLabel}`}>
            <span className={`${styles.toolTip} ${styles.genericLabel}`}>{item.macAddress}</span>
            {this.converStringbySizeHandler(item.macAddress)}
          </div>

          <div className={`${styles.tooltipTxt} ${styles.textCellBox}`}>
            {window.location.pathname != SENSOR_BOX && (
              <span className={styles.toolTip}>
                {item.timestamp
                  ? this.convertDateHandler(item.timestamp)
                  : "N/A"}
              </span>
            )}
            <span className={styles.toolTip}>
              {item.timestamp ? this.convertDateHandler(item.timestamp) : "N/A"}
            </span>
            {item.timestamp ? this.convertDateHandler(item.timestamp) : "N/A"}
          </div>
          <div className={styles.textCellBox} style={{ textAlign: "center" }}>
            <span
              className={styles.entity}
              style={{
                backgroundColor:
                  index < this.state.activeCount ? "green" : "red",
              }}
            ></span>
          </div>
        </div>
      );
    });
  };
  onClickPrevHandler = () => {
    let index =
      this.state.indexAction - 1 < 0 ||
      this.state.data[this.state.indexAction - 1] === undefined
        ? this.state.indexAction
        : this.state.indexAction - 1;
    this.setState({ indexAction: index });
  };
  onClickNextHandler = () => {
    let index =
      this.state.indexAction + 1 > this.state.data.length ||
      this.state.data[this.state.indexAction + 1] === undefined
        ? this.state.indexAction
        : this.state.indexAction + 1;
    this.setState({ indexAction: index });
  };
  onClickMenuBarHandler = () => {
    this.props.setMenuOverlaySelector(!this.props.menuOverlayRx);
  };
  tabletHandler = () => {
    return (
      this.props.isDesktop ||
      (this.props.menuOverlayRx && this.props.isTablet && !this.props.isPhone)
    );
  };
  phoneAndTabletHandler = () => {
    return (
      this.props.isDesktop ||
      (this.props.menuOverlayRx && (this.props.isTablet || this.props.isPhone))
    );
  };
  onChangeSelectHandler = (event) => {
    let item = this.state[event.target.name];
    item.value = event.target.value;
    item.err = false;
    this.setState({ [event.target.name]: item, activerow: "" }, () => {
      this.props.limitsChanged({
        activeSensorsLimit: this.state.activeSensorsLimit.value,
        inactiveSensorsLimit: this.state.inactiveSensorsLimit.value,
      });
    });
  };
  render() {
    let activePage =
      window.location.pathname == SENSOR_BOX
        ? "Sensor Box" + " "
        : "Sensors" + " ";

    return (
      <div
        className={
          this.phoneAndTabletHandler()
            ? `${styles.SearchBar} ${styles.searchBarBg}`
            : styles.SearchBar
        }
      >
        <div
          className={
            !this.tabletHandler()
              ? `${styles.photoSensor} ${styles.hideGrid}`
              : styles.photoSensor
          }
        >
          <span>
            <img className={styles.imgLogo} src={LOGO} alt="logo-hiSky" />
          </span>
          <span>
            {window.location.pathname == SENSOR_BOX ? (
              <img
                className={styles.imgSensorBox}
                src={SENSOR_BOX_IMG}
                alt="sensorBox"
              />
            ) : (
              <img className={styles.imgSensor} src={SENSOR} alt="sensor" />
            )}
          </span>
        </div>
        <div
          className={
            !this.phoneAndTabletHandler()
              ? `${styles.activeSensorWrapper} ${styles.hideGrid}`
              : window.location.pathname != SENSOR_BOX
              ? styles.activeSensorWrapper
              : styles.activeSensorWrapperBox
          }
        >
          <div className={styles.header}>
          
            <div
              className={styles.infoIconsWrapper}
              style={{ opacity: this.state.loading ? 0.6 : 1 }}
            >
              <div className={styles.infoIcons} style={{ width: "33%" }}>
                <div className={styles.outerCircle}>
                  <div>
                    <span className={styles.infoText}>
                    <strong>{this.state.activeCount}</strong>  
                    </span>
                  </div>
               
                </div>
              
                <h3 className={styles.subTitle}><strong>Active</strong></h3>
              </div>
              <div className={styles.infoIcons} style={{ width: "33%" }}>
                <div className={styles.outerCircleInactive}>
                  <div>
                    <span className={styles.infoText}>
                    <strong>{this.state.inactiveCount}</strong> 
                    </span>
                  </div>
               
                </div>
         
                <h3 className={styles.subTitle}><strong>Inactive</strong></h3>
              </div>
              <div className={styles.infoIcons} style={{ width: "33%" }}>
                <div className={styles.outerCircleTotal}>
                  <div>
                    <span className={styles.infoText}>
                   <strong> {this.state.inactiveCount + this.state.activeCount}</strong> 
                    </span>
                  </div>
                 
                </div>
            
                <h3 className={styles.subTitle}><strong>Total</strong></h3>
              </div>
           
            </div>
          </div>
        </div>
        {window.location.pathname != SENSOR_BOX && (
          <div
            className={
              !this.phoneAndTabletHandler()
                ? styles.hideGrid
                : styles.selectLimt
            }
          >
            <p className={styles.title}>
              {activePage}
              Limit
            </p>
            <div className={styles.limitWrapper}>
              <SelectWithOptions
                wrapperClass={styles.actionElementWrapper}
                optionsList={this.state.sensorsLimit}
                title="Active sensors"
                error={this.state.activeSensorsLimit.err}
                errMsg={this.state.activeSensorsLimit.errMsg}
                name="activeSensorsLimit"
                onchange={(e) => this.onChangeSelectHandler(e)}
                selected={this.state.activeSensorsLimit.value}
              />
              <SelectWithOptions
                wrapperClass={styles.actionElementWrapper}
                optionsList={this.state.sensorsLimit}
                title="Inactive sensors"
                error={this.state.inactiveSensorsLimit.err}
                errMsg={this.state.inactiveSensorsLimit.errMsg}
                name="inactiveSensorsLimit"
                onchange={(e) => this.onChangeSelectHandler(e)}
                selected={this.state.inactiveSensorsLimit.value}
              />
            </div>
          </div>
        )}
        <div
          className={styles.terminalList}
          className={
            !this.phoneAndTabletHandler()
              ? `${styles.terminalList} ${styles.hideGrid}`
              : styles.terminalList
          }
        >
          <div
            className={styles.tableDetails}
            style={{ opacity: this.state.loading ? 0.6 : 1 }}
          >
            <div className={`${styles.tableRow} ${styles.headerBorder}`}>
              {window.location.pathname != SENSOR_BOX
                ? this.buildTableHeader()
                : this.buildTableHeaderSensorBox()}
            </div>
            <div className={styles.table}>
              {window.location.pathname != SENSOR_BOX
                ? this.buildTableRow()
                : this.buildTableRowForSensorBox()}
            </div>
            <Pagination
              style={styles}
              amount={this.state.originalData.length}
              pageNumber={this.state.indexAction}
              prev={this.onClickPrevHandler}
              next={this.onClickNextHandler}
              pageCount={
                this.state.data.length
                  ? this.state.data[this.state.indexAction].length
                  : 0
              }
            />
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <SpinnerLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    menuOverlayRx: state.common.menuOverlayRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actionType.setAuth(auth)),
    setMenuOverlaySelector: (data) =>
      dispatch(actionType.setMenuOverlaySelector(data)),
  };
};
export default connect(mapStateProps, mapStateDispatch)(withRouter(SearchBar));
